
import {inject, NgZone} from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from '@angular/fire/auth';
import { User } from '@firebase/auth';

// Helper function to get custom claims from the user
function getCustomClaimRole(currentUser: User, force?: boolean) {
    return currentUser.getIdTokenResult(force? force: false);
}

/**
 * AuthGuard is a function that determines if a user can activate a particular route based on their authentication state and subscription status.
 *
 * It checks if the user is authenticated and possesses a valid "stripeRole".
 * If the user is not authenticated, they are redirected to the "/auth/login" route.
 * If the user does not have a "stripeRole" and is not attempting to access the "/subscription" route, they are redirected to the "/subscription" route.
 * If the user has a "stripeRole" or is accessing the "/subscription" route, they are allowed access.
 *
 * @param {ActivatedRouteSnapshot} next - The snapshot of the route the user is attempting to activate.
 * @param {RouterStateSnapshot} state - The state of the router at the time of activation.
 * @return {Promise<boolean>} A promise that resolves to a boolean indicating whether the route can be activated.
 */
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
    const router = inject(Router);
    const auth = inject(Auth);
    const ngZone = inject(NgZone);

    return new Promise((resolve) => {
        auth.onAuthStateChanged(async (user) => {
            // 1. If there is no currentUser, redirect to auth/login
            if (!user) {
                await ngZone.run(async () => {
                    await router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
                    resolve(false);
                });
            } else if (user) {
                try {
                    let tokenResult = await getCustomClaimRole(user);
                    let stripeRole = tokenResult.claims.stripeRole;

                    // 2. If there is a currentUser without a "stripeRole", redirect to /subscription
                    if (!stripeRole && !state.url.includes('/subscription')) {
                        // refresh token to check whether the state changed
                        tokenResult = await getCustomClaimRole(user, true);
                        console.log("guard refreshuje +"+state.url)
                        stripeRole = tokenResult.claims.stripeRole;
                        if (!stripeRole && !state.url.includes('/subscription')) {
                            await ngZone.run(async () => {
                                await router.navigate(['/subscription']);
                                resolve(false);
                            });
                        } else {
                            resolve(true);
                        }
                    }

                    // 3. If the user has a "stripeRole" or is already at /subscription, allow access
                    else if (stripeRole || state.url.includes('/subscription')) {
                        resolve(true);
                    }

                    // If none of the conditions are met, redirect to login
                    else {
                        await ngZone.run(async () => {
                            await router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
                            resolve(false);
                        });
                    }
                } catch (error) {
                    console.error('Error fetching token claims:', error);
                    await ngZone.run(async () => {
                        await router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
                        resolve(false);
                    });
                }
            }
        });
    });
};
