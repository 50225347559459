<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <div class="menu-icons">
            <ul class="list-unstyled topnav-menu float-end mb-0">
                <li class="d-inline-block d-lg-none" ngbDropdown placement="bottom-start">
                    <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0);" role="button"
                       ngbDropdownToggle>
                        <i class="mdi mdi-bell-outline"></i>
                    </a>
                    <div class="dropdown-menu-end  dropdown-lg p-0 noti-dropdown" ngbDropdownMenu>
                        <form class="p-3" ngbDropdownItem>
                            <input type="text" class="form-control" placeholder="Search ..."
                                   aria-label="Recipient's username">
                        </form>
                    </div>
                </li>

                <!-- full screen button -->
                <li class="dropdown d-none d-lg-inline-block">
                    <a class="nav-link dropdown-toggle arrow-none maximize-icon"
                       href="javascript:void(0)" (click)="toggleFullScreen()">
                        <span class="iconify" data-width="32" data-height="32"
                              data-icon="fluent:full-screen-maximize-24-regular"></span>
                    </a>
                </li>


                <li class="d-none d-lg-inline-block topbar-dropdown" ngbDropdown>
                    <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0)" role="button"
                       ngbDropdownToggle>
                        <span class="iconify" data-width="32" data-height="32"
                              data-icon="fluent:translate-auto-24-regular"></span>
                    </a>
                    <div class="dropdown-menu-end" ngbDropdownMenu>

                        <!-- item   flagpack:us -->
                        <a (click)="selectLanguage(language)" ngbDropdownItem
                           *ngFor="let language of this.languageService.getSupportedLanguages()">
                        <span>
                            <span class="iconify" data-width="24" data-height="24"
                                  [dataIcon]="'flagpack:'+language.flag"></span>
                            <span class="text-primary ms-1">{{ language.name }}</span>
                         </span>
                        </a>

                    </div>
                </li>


                <!-- profile menu -->
                <li class="dropdown notification-list  topbar-dropdown" ngbDropdown>
                    <a ngbDropdownToggle class="nav-link nav-user  me-0 waves-effect waves-light"
                       id="profileMenuDropdown"
                       href="javascript:void(0)" aria-expanded="false">
                        <span class="iconify" data-width="32" data-height="32"
                              data-icon="fluent:person-circle-24-regular"></span>
                        <span class="pro-user-name ms-1">
                        {{ loggedInUser.firstName }} <i class="mdi mdi-chevron-down"></i>
                    </span>

                    </a>
                    <div ngbDropdownMenu aria-labelledby="profileMenuDropdown"
                         class="dropdown-menu-end profile-dropdown">
                        <!-- item -->
                        <a *ngFor="let option of profileOptions;" class="notify-item" ngbDropdownItem [routerLink]="option.redirectTo">
                            <span class="iconify" data-width="24" data-height="24" [dataIcon]="option.icon"></span><span class="text-primary ms-1">{{ option.label }}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <!-- LOGO -->
        <div class="logo-box">
            <a [routerLink]="'/dashboard'" class="logo logo-dark text-center">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="20" *ngIf="layoutType!=='two-column'">
                    <img src="assets/images/logo-dark-2.png" alt="" height="20" *ngIf="layoutType==='two-column'">
                </span>
            </a>

            <a [routerLink]="'/dashboard'" class="logo logo-light">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-light.png" alt="" height="70" *ngIf="layoutType!=='two-column'">
                    <img src="assets/images/logo-light-2.png" alt="" height="20" *ngIf="layoutType==='two-column'">
                </span>
            </a>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button class="button-menu-mobile waves-effect waves-light d-none d-lg-block"
                        (click)="changeSidebarWidth()" *ngIf="layoutType!=='horizontal'">
                    <span class="iconify" data-width="24" data-height="24"
                          data-icon="line-md:close-to-menu-transition"></span>
                </button>
            </li>

            <li>
                <button class="button-menu-mobile open-left d-block d-lg-none waves-effect waves-light"
                        *ngIf="layoutType!=='horizontal'" (click)="toggleMobileMenu($event)">
                    <span class="iconify" data-width="24" data-height="24"
                          data-icon="line-md:close-to-menu-transition"></span>
                </button>

            </li>

            <li>
                <!-- Mobile menu toggle (Horizontal Layout)-->
                <a class="navbar-toggle nav-link" [ngClass]="{'open': topnavCollapsed}"
                   (click)="toggleMobileMenu($event)" *ngIf="layoutType==='horizontal'">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
</div>
<!-- end Topbar -->
