import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

/**
 * This service is used to share text in editor components.
 */
@Injectable({
    providedIn: 'root'
})
export class EditorService {

    private textSelection: string;

    private textDocument: string;

    private innerClipboardSubject: Subject<string> = new Subject<string>();

    private imageSelected: string;

    constructor() {
    }

    public observeClipboard(): Observable<string> {
        return this.innerClipboardSubject.asObservable();
    }

    public setClipboard(value: string): void {
        this.innerClipboardSubject.next(value);
    }

    public getCurrentSelection(): string {
        return this.textSelection;
    }

    public getCurrentDocumentText(): string {
        return this.textDocument;
    }

    public setSelection(selection: string) {
        this.textSelection = selection;
    }

    public setDocumentText(documentText: string) {
        this.textDocument = documentText;
    }

    public setImageSelected(imageText: string) {
        this.imageSelected = this.extractSrcFromImgTag(imageText);
        console.log("Image: "+this.imageSelected);
    }

    public getImageSelected(): string {
        return this.imageSelected;
    }

    private extractSrcFromImgTag(imgTag: string): string | null {
        // Regular expression to match the src attribute and its complete value
        const srcRegex = /<img\s+[^>]*src="([^"]+)"[^>]*>/i;

        // Execute the regular expression on the input string
        const match = srcRegex.exec(imgTag);

        // If a match is found, return the complete src content
        if (match && match[1]) {
            return match[1];
        }

        // If no match is found, return null
        return null;
    }

}
