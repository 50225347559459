import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-editable-label',
  templateUrl: './editable-label.component.html',
  styleUrls: ['./editable-label.component.scss']
})
export class EditableLabelComponent {

  public isEditing = false;

  @Input()
  public currentText = '';

  private originalText: string = '';

  @Output()
  textUpdated: EventEmitter<string> = new EventEmitter();

  constructor() { }

  enableEdit(): void {
    this.originalText = this.currentText;
    this.isEditing = true;
  }

  saveEdit(newText: string): void {
    this.textUpdated.emit(newText);
    this.isEditing = false;
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.currentText = this.originalText;
  }
}
