<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick(entity)"
     (mouseenter)="onMouseEnter(entity)"
     (mouseleave)="onMouseLeave(entity)"
     [ngClass]="{'card-hover': isHovered}"
>
    <div class="hovno animate__fadeIn animate__animated">
        <div class="card-body">
            <div class="main-content">
                <div class="row">
                    <div class="text-md-end" [class.hidden]="!isHovered" *ngIf="taggable != TaggableType.SYSTEM">
                        <span (click)="onDeleteClick($event)">
                        <span class="iconify-inline" data-width="24" data-height="24"
                              data-icon="line-md:menu-to-close-alt-transition" color="#ff0080"></span>
                        </span>
                    </div>
                    <div class="text-md-end" [class.hidden]="!isHovered" *ngIf="taggable == TaggableType.SYSTEM">
                        <span (click)="onFavoriteClick($event)">
                        <span class="iconify-inline" data-width="24" data-height="24"
                              data-icon="line-md:star-alt" color="#f9d220"></span>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="pt-2 pb-2">
                        <span class="iconify" data-width="24" data-height="24" [dataIcon]="entity.iconKey" *ngIf="entity.iconKey"></span>
                        <h4 class="mt-3">{{ entity.templateName }}</h4>
                        <p>{{ entity.templateDescription?.length > 180 ? (entity.templateDescription | slice:0:180) + '...' : entity.templateDescription }}</p>
                    </div>
                </div>

                <!-- https://storage.googleapis.com/guideglare_gallery/img/9f31ff63-3ced-4119-b98b-bcbc3a74146f/73f7ffa4-caa6-49a2-a65a-2da5ccb32075_thumb -->

                <div class="row">
                </div>
            </div>
            <div class="row mt-1">
            </div>
        </div>
    </div>
</div>
