import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ToastType, ExtendedToastModel, ToastService} from "../../service/toast.service";
import {Subscription} from "rxjs";
import {ToastComponent, ToastModel} from "@syncfusion/ej2-angular-notifications";
import {cssClass} from "@syncfusion/ej2-lists";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-toast',
    templateUrl: './my-toast.component.html',
    styleUrls: ['./my-toast.component.scss']
})
export class MyToastComponent implements OnInit, OnDestroy {


    @ViewChild('toast', {static: false}) public toastObj: ToastComponent;
    public toastConfig: ToastModel = {
        title: 'Notification',
        content: '',
        //showCloseButton: true,
        timeOut: 50000,
        //showProgressBar: true,
        position: {X: 'Right', Y: 'Bottom'}
    };


    private toastSubscription: Subscription;

    constructor(private toastService: ToastService, private translateService: TranslateService) {
    }


    ngOnInit(): void {
        this.toastSubscription = this.toastService.subscribe(ToastType.TOAST, payload => {
            if (payload.level) {
                this.showToast(payload, "e-toast-" + payload.level);
            } else {
                this.showToast(payload);
            }
        })
    }

    ngOnDestroy(): void {
        this.toastSubscription.unsubscribe();
    }


    showToast(payload: ExtendedToastModel, cssClass?: string): void {
        payload.title  = this.translateService.instant(payload.title as string);

        switch (payload.level) {
            case "info":
                payload.title = '<span class="me-1"><span class="iconify" data-width="22" data-height="22" data-icon="line-md:chat-round"></span></span>' + payload.title;
                break;
            case "success":
                payload.title = '<span class="me-1"><span class="iconify" data-width="22" data-height="22" data-icon="line-md:circle-to-confirm-circle-transition"></span></span>' + payload.title;
                break;
            case "warning":
                payload.title = '<span class="me-1"><span class="iconify" data-width="22" data-height="22" data-icon="line-md:alert"></span></span>' + payload.title;
                break;
            case "danger":
                payload.title = '<span class="me-1"><span class="iconify" data-width="22" data-height="22" data-icon="line-md:close-circle"></span></span>' + payload.title;
                break;
        }


        this.toastConfig = {
            ...this.toastConfig,
            title: payload.title || this.toastConfig.title,
            content: this.translateService.instant(payload.content as string) || '',
            cssClass: cssClass
        };
        this.toastObj.show(this.toastConfig);
        {
        }
    }

}
