import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagEntity} from "../../service/tags/tag.model";
import {RSQLCriteria, RSQLFilterBuilder} from "rsql-criteria-typescript";
import {RsqlHelper} from "../../../shared/utils/rsql-helper";

@Component({
    selector: 'app-entity-filter',
    templateUrl: './entity-filter.component.html',
    styleUrls: ['./entity-filter.component.scss']
})
export class EntityFilterComponent {

    @Input()
    type?: string;

    @Input()
    entityName!: string;

    @Input()
    parentId?: string;

    @Output()
    filter: EventEmitter<string> = new EventEmitter<string>();

    isCollapsed: boolean = true;
    searchTitle: string = '';
    selectedTags: TagEntity[] = [];

    showTagPopover: boolean = false;


    emitFilter(): void {
        const rsql = this.getActualFilter();
        this.filter.emit(rsql);
    }


    public getActualFilter(): string {
        const rsql = new RSQLCriteria();
        const builder = new RSQLFilterBuilder();

        if(this.type){
            builder.column(`${this.entityName}.type`).equalTo(this.type)
        }

        // if used with "parent"
        if (this.parentId) {
            builder.column(`${this.entityName}.parentId`).equalTo(this.parentId)
        }

        if (this.searchTitle.length > 2) {
            builder.column(`${this.entityName}.title`).like(`*${this.searchTitle}*`);
        }

        if (this.selectedTags.length > 0) {
            builder.column(`${this.entityName}.tags`).in(this.selectedTags.map(value => value.id))
        }
        rsql.filters.and(builder.toList());
        return RsqlHelper.toQuery(rsql);
    }

    handleAddTag(tag: TagEntity) {
        this.selectedTags.push(tag);
    }

    handleRemoveTag(tag: TagEntity) {
        this.selectedTags = this.selectedTags.filter(t => t.id !== tag.id);
    }
}
