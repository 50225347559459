import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {StorageApiDto} from "../../../apps/files/shared/files.model";
import {EventService} from "../../../core/service/event.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-autosave-button',
    templateUrl: './autosave-button.component.html',
    styleUrls: ['./autosave-button.component.scss']
})
export class AutosaveButtonComponent implements OnInit, OnDestroy {

    private saveDocumentTimer: any;

    countdownValue: number = 60;

    waitHover: boolean = false;

    buttonState: 'waiting' | 'saved' | 'hidden' | 'error' = 'hidden';

    saveSubscription: Subscription;
    resetSubscription: Subscription;

    @Output()
    timeout: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    storage!: StorageApiDto;

    constructor(private ngZone: NgZone,
                private cd: ChangeDetectorRef,
                private eventService: EventService) {
    }

    ngOnDestroy(): void {
        this.saveSubscription.unsubscribe();
        this.resetSubscription.unsubscribe();
    }

    ngOnInit(): void {
        console.log("Will subscribe to event "+ this.storage.id)
        this.saveSubscription = this.eventService.subscribe("saved-" + this.storage.id, (saved) => {
            this.onSaveComplete(saved);
        })
        this.resetSubscription = this.eventService.subscribe("reset-" + this.storage.id, () => {
            this.resetTimer();
        })

    }

    forceUpload() {
        clearInterval(this.saveDocumentTimer);
        this.countdownValue = 0;
        this.waitHover = false;
        this.timeout.emit();
    }

    resetTimer() {
        this.countdownValue = 60;
        this.buttonState = 'waiting';
        this.cd.detectChanges(); // Trigger change detection to update the view

        if (this.saveDocumentTimer) {
            clearTimeout(this.saveDocumentTimer);
        }

        // Running this outside Angular's zone to prevent performance degradation
        this.ngZone.runOutsideAngular(() => {
            this.saveDocumentTimer = setInterval(() => {
                this.countdownValue--;
                if (this.countdownValue === 0) {
                    clearInterval(this.saveDocumentTimer);
                    this.ngZone.run(() => {
                        this.timeout.emit();
                    });
                } else {
                    // Optimize change detection
                    this.ngZone.run(() => {
                        this.cd.detectChanges();
                    });
                }
            }, 1000);
        });
    }

    onSaveComplete(success: boolean) {
        if (success) {
            this.buttonState = 'saved';
        } else {
            this.buttonState = 'error';
        }
        this.cd.detectChanges();
        setTimeout(() => {
            this.buttonState = 'hidden';
            this.cd.detectChanges();
        }, 5000); // Hide after 5 seconds
    }


}
