<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick(entity)"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [ngClass]="{'card-selected': entity.selected}"
>
    <div class="hovno animate__fadeIn animate__animated" [style.height]="height+'px'"
         [style.max-height]="maxHeight+'px'">
        <div class="card-body">
            <div class="main-content">
                <div class="row icon-line" *ngIf="entity.icon && height > 79">
                    <span class="iconify" [attr.data-icon]="entity.icon" data-width="32"></span>
                </div>
                <div class="row" *ngIf="entity.image">
                    <img src="{{entity.image}}" alt="model">
                </div>
                <div class="row">
                    <span *ngIf="entity.icon && height < 80" class="iconify" [attr.data-icon]="entity.icon"
                          data-width="28"></span>
                    <h5>{{ entity.title | translate }}</h5>
                    <p>{{ entity.description | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
