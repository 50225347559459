<div class="card" (click)="$event.stopPropagation()">
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <span *ngIf="entityTags.length != 0">Selected:</span>
                <div class="entity-tags">
                <span *ngFor="let tag of entityTags" [ngClass]="'me-1 mb-1 badge bg-' + tag.color">
                  {{ tag.title }}
                    <span (click)="removeTag(tag)">
                   <span class="iconify" data-icon="material-symbols:cancel-outline"></span>
                        </span>
                </span>
                    <span *ngIf="entityTags.length == 0">

                    </span>
                </div>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-8">
                <input [(ngModel)]="searchTerm"
                       [ngbTypeahead]="search"
                       [resultFormatter]="formatter"
                       [inputFormatter]="formatter"
                       type="text" class="form-control" placeholder="Type..."/>
            </div>
            <div class="col-4">
                <button class="btn btn-outline-primary"
                        [disabled]="!canBeSaved()"
                        (click)="onSaveTagClick()"
                >Save
                </button>
            </div>
        </div>
    </div>
</div>
