import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-load-card',
  templateUrl: './load-card.component.html',
  styleUrls: ['./load-card.component.scss']
})
export class LoadCardComponent {

  @Input()
  remainingEntities!: number;

  @Output()
  cardClicked: EventEmitter<any> = new EventEmitter<any>();

  isHovered: boolean = false;

  onCardClick() {
    this.cardClicked.emit(true);
  }

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }


}
