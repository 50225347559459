import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PreloaderComponent} from "./components/preloader/preloader.component";
import { TagComponent } from './components/tags/tag/tag.component';
import { TagPopoverComponent } from './components/tags/tag-popover/tag-popover.component';
import {FormsModule} from "@angular/forms";
import {
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionDirective,
    NgbAccordionHeader,
    NgbAccordionItem,
    NgbCollapse,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbNav,
    NgbNavContent, NgbNavItem, NgbNavLink, NgbNavLinkBase, NgbNavOutlet,
    NgbPopover, NgbTooltip,
    NgbTypeahead
} from "@ng-bootstrap/ng-bootstrap";
import { EntityFilterComponent } from './components/entity-filter/entity-filter.component';
import { TagSubfilterComponent } from './components/entity-filter/tag-subfilter/tag-subfilter.component';
import { EntityTableComponent } from './components/entity-table/entity-table.component';
import { ReadonlyRichTextEditorComponent } from './readonly-editor/readonly-rich-text-editor.component';
import { AdvancedEditorComponent } from '../apps/papers/shared/component/advanced-editor/advanced-editor.component';
import {DocumentEditorContainerAllModule} from "@syncfusion/ej2-angular-documenteditor";
import {ToastAllModule} from "@syncfusion/ej2-angular-notifications";
import {EntityCardsComponent} from "./components/entity-cards/entity-cards.component";
import { EntityCardComponent } from './components/entity-cards/entity-card/entity-card.component';
import {EditableLabelComponent} from "./components/editable-label/editable-label.component";
import { TagDialogComponent } from './components/tags/tag-dialog/tag-dialog.component';
import {EmptyCardComponent} from "./components/entity-cards/empty-card/empty-card.component";
import { LoadCardComponent } from './components/entity-cards/load-card/load-card.component';
import { AiImgEditorComponent } from './ai-img-editor/ai-img-editor.component';
import {ImageEditorAllModule} from "@syncfusion/ej2-angular-image-editor";
import { SelectableCardComponent } from './components/selectable-card/selectable-card.component';
import { GalleryCardComponent } from './components/entity-cards/gallery-card/gallery-card.component';
import { ImageMaskComponent } from './components/image-mask/image-mask.component';
import {RichTextEditorModule} from "@syncfusion/ej2-angular-richtexteditor";
import { EntitySimpleFilterComponent } from './components/entity-simple-filter/entity-simple-filter.component';
import {SimplebarAngularModule} from "simplebar-angular";
import { GradientButtonComponent } from './components/gradient-button/gradient-button.component';
import { EnterSubmitDirective } from './directive/enter-submit.directive';
import { AutosaveButtonComponent } from './components/autosave-button/autosave-button.component';
import { TemplateCardComponent } from './components/entity-cards/template-card/template-card.component';
import {LayoutSharedModule} from "../layout/shared/layout-shared.module";
import { TenantStatisticsComponent } from './statistics/tenant-statistics/tenant-statistics.component';
import {ProgressBarAllModule} from "@syncfusion/ej2-angular-progressbar";
import { AiCustomActionComponent } from './ai-custom-action/ai-custom-action.component';
import {MyToastComponent} from "./components/toast/my-toast.component";
import { IconWidthDirective } from './directive/icon-width.directive';
import { AngularHeightDirective } from './directive/angular-height.directive';
import { ImageHistoryComponent } from './components/image-history/image-history.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {HttpLoaderFactory} from "../app.module";
import { LanguageChooserComponent } from './components/language-chooser/language-chooser.component';
import { EnumerationListComponent } from './components/enumeration-list/enumeration-list.component';
import { EnumerationChooserComponent } from './components/enumeration-chooser/enumeration-chooser.component';


@NgModule({
    declarations: [
        PreloaderComponent,
        TagComponent,
        TagPopoverComponent,
        EntityFilterComponent,
        TagSubfilterComponent,
        EntityCardsComponent,
        EntityTableComponent,
        ReadonlyRichTextEditorComponent,
        AdvancedEditorComponent,
        EntityCardComponent,
        EntityCardComponent,
        EditableLabelComponent,
        TagDialogComponent,
        EmptyCardComponent,
        EmptyCardComponent,
        LoadCardComponent,
        AiImgEditorComponent,
        SelectableCardComponent,
        GalleryCardComponent,
        ImageMaskComponent,
        EntitySimpleFilterComponent,
        GradientButtonComponent,
        EnterSubmitDirective,
        AutosaveButtonComponent,
        TemplateCardComponent,
        TenantStatisticsComponent,
        AiCustomActionComponent,
        MyToastComponent,
        IconWidthDirective,
        AngularHeightDirective,
        ImageHistoryComponent,
        LanguageChooserComponent,
        EnumerationListComponent,
        EnumerationChooserComponent,
    ],
    exports: [
        PreloaderComponent,
        TagComponent,
        EntityFilterComponent,
        EntityCardsComponent,
        EntityTableComponent,
        ReadonlyRichTextEditorComponent,
        AdvancedEditorComponent,
        EditableLabelComponent,
        AiImgEditorComponent,
        SelectableCardComponent,
        ImageMaskComponent,
        EnterSubmitDirective,
        GradientButtonComponent,
        EmptyCardComponent,
        AutosaveButtonComponent,
        TenantStatisticsComponent,
        AiCustomActionComponent,
        MyToastComponent,
        AngularHeightDirective,
        IconWidthDirective,
        ImageHistoryComponent,
        TranslateModule,
        LanguageChooserComponent,
        EnumerationChooserComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbPopover,
        NgbTypeahead,
        NgbAccordionDirective,
        NgbAccordionItem,
        NgbAccordionButton,
        NgbAccordionHeader,
        NgbAccordionCollapse,
        NgbAccordionBody,
        NgbCollapse,
        NgbDropdownItem,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgbDropdown,
        NgbNav,
        NgbNavContent,
        NgbNavLink,
        NgbNavLinkBase,
        NgbNavItem,
        NgbNavOutlet,
        DocumentEditorContainerAllModule,
        ToastAllModule,
        ImageEditorAllModule,
        RichTextEditorModule,
        SimplebarAngularModule,
        LayoutSharedModule,
        ProgressBarAllModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbTooltip
    ]
})
export class SharedModule { }
