import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output, SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-selectable-card',
    templateUrl: './selectable-card.component.html',
    styleUrls: ['./selectable-card.component.scss'],
})
export class SelectableCardComponent implements OnChanges{

    @Input()
    entity!: any;

    @Input() height: number;
    @Input() maxHeight: number;

    @Output()
    cardClicked: EventEmitter<any> = new EventEmitter<any>();

    isHovered: any;
    isSelected: any;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity) {
            this.cdr.markForCheck();
        }
    }

    onCardClick(entity: any) {
        this.cardClicked.emit(entity.id);
    }

    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseLeave() {
        this.isHovered = false;
    }

}
