import {Injectable} from '@angular/core';

import {Router} from "@angular/router";
import {
    Auth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    applyActionCode,
    confirmPasswordReset,
    signOut,
    inMemoryPersistence,
    browserLocalPersistence,
    User,
    UserCredential
} from "@angular/fire/auth";

import {setPersistence} from "@firebase/auth"
import {LoggingService} from "./logging.service";

@Injectable({providedIn: 'root'})
export class AuthenticationService {

    constructor(private router: Router,
                private auth: Auth,
                private logger: LoggingService
    ) {}

    // Sign in with email/password
    signIn(email: string, password: string, rememberMe: boolean): Promise<UserCredential | null> {
        return setPersistence(this.auth, rememberMe ? browserLocalPersistence : inMemoryPersistence)
            .then(() => {
                return signInWithEmailAndPassword(this.auth, email, password)
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                this.logger.error("User login failed.", errorMessage, errorCode);
                throw error;
            });
    }

    // Sign up with email/password
    signUp(name: string, email: string, password: string): Promise<User | null> {
        return createUserWithEmailAndPassword(this.auth, email, password)
            .then((result) => {
                this.sendVerificationMail()
                    .catch(reason => {
                        this.logger.error("Sending email verification failed.", reason);
                    });
                return result.user;
            })
            .catch((error) => {
                this.logger.error("User signup failed.", error);
                throw error;
            });
    }

    // Send email verification when new user sign up
    sendVerificationMail(): Promise<void | null> {
        return sendEmailVerification(this.auth.currentUser)
    }

    // Reset Forgot password
    sendForgottenPasswordResetEmail(passwordResetEmail: string): Promise<void | null> {
        return sendPasswordResetEmail(this.auth, passwordResetEmail)
    }

    verifyEmail(oobCode: string): Promise<void | null> {
        return applyActionCode(this.auth, oobCode);
    }

    /**
     * Returns the current user
     */
    public currentUser(): User | null {
        return this.auth.currentUser;
    }

    public getTenantId(): string {
        return this.auth.currentUser?.email;
    }

    /**
     * Logout the user
     */
    logout(silent: boolean = false): Promise<void> {
        return signOut(this.auth)
            .then(() => {
                if (!silent) {
                    this.router.navigate(['/auth/logout'])
                }
            }).catch(error => {
                this.logger.error("Logout failed.", error);
                throw error
            });
    }

    resetPassword(password: string, oobCode: string): Promise<void> {
        return confirmPasswordReset(this.auth, oobCode, password);
    }
}

