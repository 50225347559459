import {Component, AfterViewInit, ViewChild, ElementRef, Input, SimpleChanges} from '@angular/core';
import Konva from 'konva';

@Component({
    selector: 'app-image-mask',
    templateUrl: './image-mask.component.html',
    styleUrls: ['./image-mask.component.scss']
})
export class ImageMaskComponent implements AfterViewInit {

    @Input()
    base64Image: string;

    @ViewChild('container', {static: false}) container: ElementRef;

    private stage: Konva.Stage;
    private imageLayer: Konva.Layer;
    private maskLayer: Konva.Layer;
    private image: Konva.Image;
    private originalWidth: number;
    private originalHeight: number;
    private scaledWidth: number;
    private scaledHeight: number;
    private ratio: number;
    private isDrawing: boolean;

    ngAfterViewInit() {
        this.initializeStage();
        if (this.base64Image) {
            this.loadImage(this.base64Image);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.base64Image && !changes.base64Image.isFirstChange()) {
            this.loadImage(changes.base64Image.currentValue);
        }
    }

    initializeStage() {
        this.stage = new Konva.Stage({
            container: this.container.nativeElement,
            width: 800,
            height: 600
        });

        this.imageLayer = new Konva.Layer();
        this.maskLayer = new Konva.Layer();
        this.stage.add(this.imageLayer);
        this.stage.add(this.maskLayer);

        this.stage.on('mousedown', () => {
            this.isDrawing = true;
            this.stage.on('mousemove', this.draw.bind(this));
        });

        this.stage.on('mouseup', () => {
            this.isDrawing = false;
            this.stage.off('mousemove', this.draw.bind(this));
        });
    }

    loadImage(base64Image: string) {
        const imgObj = new Image();
        imgObj.onload = () => {
            if (this.image) {
                this.image.destroy();
            }

            this.originalWidth = imgObj.naturalWidth;
            this.originalHeight = imgObj.naturalHeight;

            this.ratio = imgObj.width / 800;
            console.log("Ratio: " + this.ratio);

            this.scaledWidth = this.originalWidth / this.ratio;
            this.scaledHeight = this.originalHeight / this.ratio;

            console.log("scaledWidth: " + this.scaledWidth);
            console.log("scaledHeight: " + this.scaledHeight);

            this.image = new Konva.Image({
                x: 0,
                y: 0,
                image: imgObj,
                width: this.scaledWidth,
                height: this.scaledHeight
            });
            this.imageLayer.add(this.image);
            this.imageLayer.draw();
        };
        imgObj.src = base64Image;
    }

    draw(e: any) {
        if (this.isDrawing == true) {
            const pos = this.stage.getPointerPosition();
            const circle = new Konva.Circle({
                x: pos.x,
                y: pos.y,
                radius: 10,
                fill: 'white',
                stroke: 'white',
                strokeWidth: 1
            });
            this.maskLayer.add(circle);
            this.maskLayer.draw();
        }
    }

    /*
    saveMask() {
        const dataURL = this.getResizedMaskBase64(this.originalWidth, this.originalHeight);
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'mask.png';
        link.click();
    }
*/
    saveMask() {
        this.getResizedMaskBase64(this.originalWidth, this.originalHeight).then(dataURL => {
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'mask.png';
            link.click();
        });
    }


    async getResizedMaskBase64(width: number, height: number): Promise<string> {
        const maskCanvas = document.createElement('canvas');
        maskCanvas.width = width;
        maskCanvas.height = height;
        const context = maskCanvas.getContext('2d');

        const maskDataURL = this.maskLayer.toDataURL({mimeType: 'image/png'});
        const maskImg = new Image();
        await new Promise<void>(resolve => {
            maskImg.onload = () => {
                context.drawImage(maskImg, 0, 0, this.scaledWidth, this.scaledHeight, 0, 0, width, height);
                resolve();
            };
            maskImg.src = maskDataURL;
        });

        return maskCanvas.toDataURL('image/png');
    }
}
