import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalLayoutComponent } from './layout/layout.component';
import { LayoutSharedModule } from '../shared/layout-shared.module';
import { RouterModule } from '@angular/router';
import {SharedModule} from "../../shared/shared.module";



@NgModule({
  declarations: [
    VerticalLayoutComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        LayoutSharedModule,
        SharedModule
    ],
  exports: [
    VerticalLayoutComponent
  ]
})
export class VerticalModule { }
