<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick(entity)"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
>
    <div class="hovno btn animate__fadeIn animate__animated align-items-center d-flex" [style.height.px]="height" >
        <div class="row flex-grow-1 align-items-center">
            <div class="col-2">
                <span *ngIf="entity.icon" class="iconify" [attr.data-icon]="entity.icon" data-width="24"
                      data-height="24"></span>
            </div>
            <div class="col-{{col}} col-md-{{col-2}} flex-column">
                <span class="text-primary">{{ entity.title | translate }}</span>
            </div>

        </div>
    </div>
</div>
