import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TaggableType} from "../../../service/tags/tag.service";
import {AuthenticationService} from "../../../../core/service/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss']
})
export class GalleryCardComponent {

  @Input()
  entity!: any;

  @Input()
  taggable!: TaggableType;


  @Output()
  cardClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  isHovered: boolean = false;

  constructor(protected authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    /*
      this.paperService.getPaperTemplate(this.template.id!).subscribe(value => {
        this.template = value;
      })*/
  }

  onCardClick(card: any) {
    this.cardClicked.emit(card);
  }

  onMouseEnter(template: any) {
    this.isHovered = true;
  }

  onMouseLeave(template: any) {
    this.isHovered = false;
  }


  onTitleUpdated($event: any) {

  }

  onDeleteClick(event: Event) {
    console.log("Delete clicked");
    this.deleteClicked.emit();
    event.stopPropagation();
  }

  protected readonly environment = environment;
}
