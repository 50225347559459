<div class="row">
    <div class="input-group">
        <input type="text"
               id="glare-title-in"
               name="glare-title"
               class="form-control search-input"
               placeholder="{{'entity.filter.search.placeholder' | translate }}"
               required
               ngDefaultControl
               (keydown.enter)="onEnter($event)"
               [(ngModel)]="searchTitle">
        <button *ngIf="searchTitle"
                type="button"
                class="btn btn-outline-secondary clear-button"
                (click)="clearSearch()">
            <span class="iconify" data-icon="line-md:close" data-width="18" data-height="18"></span>
        </button>
        <button
                type="button"
                class="btn search-button border-collapse"
                aria-controls="filter"
                (click)="emitFilter()">
            <span class="iconify" data-icon="line-md:search" data-width="18" data-height="18"></span>
        </button>
    </div>
</div>
