import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TaggableType} from "../../../service/tags/tag.service";
import {AuthenticationService} from "../../../../core/service/auth.service";

@Component({
    selector: 'app-entity-card',
    templateUrl: './entity-card.component.html',
    styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent {

    @Input()
    entity!: any;

    @Input()
    taggable!: TaggableType;


    @Output()
    cardClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    deleteClicked: EventEmitter<any> = new EventEmitter<any>();

    isHovered: boolean = false;

    constructor(protected authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        /*
          this.paperService.getPaperTemplate(this.template.id!).subscribe(value => {
            this.template = value;
          })*/
    }

    onCardClick(card: any) {
        this.cardClicked.emit(card);
    }

    onMouseEnter(template: any) {
        this.isHovered = true;
    }

    onMouseLeave(template: any) {
        this.isHovered = false;
    }


    onTitleUpdated($event: any) {

    }

    onDeleteClick(event: Event) {
        console.log("Delete clicked");
        this.deleteClicked.emit();
        event.stopPropagation();
    }

    protected readonly TaggableType = TaggableType;
}
