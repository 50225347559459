import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {createRequestOption} from "../utils/request-utils";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AbstractEntityServiceService<T> {

    baseUrl: string = '';

    constructor(protected httpClient: HttpClient) {
    }

    findAll(): Observable<T[]> {
        return this.httpClient.get(this.baseUrl) as Observable<T[]>;
    }

    findAllPaged(page: number, size: number, req?: any): Observable<{ entities: T[], totalItems: number }> {
        const headers = new HttpHeaders({
            'X-Page-Number': `${page}`,
            'X-Page-Size': `${size}`
        });
        const options = createRequestOption(req);
        return this.httpClient.get<T[]>(this.baseUrl, {headers: headers, params: options, observe: 'response'})
            .pipe(
                map(response => {
                    const totalItems = parseInt(<string>response.headers.get('X-Total-Count'), 10);
                    return {entities: response.body ?? [], totalItems};
                })
            );
    }


    findById(id: string): Observable<T> {
        return this.httpClient.get(`${this.baseUrl}/${id}`) as Observable<T>;
    }

    deleteById(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
    }

    updateById(id: string, payload: any): Observable<T> {
        return this.httpClient.put(`${this.baseUrl}/${id}`, payload) as Observable<T>;
    }

    create(payload: any): Observable<T> {
        return this.httpClient.post(`${this.baseUrl}`, payload) as Observable<T>;
    }


}
