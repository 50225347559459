import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutContainerComponent } from './layout-container.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { LayoutSharedModule } from './shared/layout-shared.module';
import { VerticalModule } from './vertical/vertical.module';



@NgModule({
  declarations: [
    LayoutContainerComponent,
    PublicLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutSharedModule,
    VerticalModule,
  ],
  exports: [LayoutContainerComponent, PublicLayoutComponent]
})
export class LayoutModule { }
