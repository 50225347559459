import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dataIcon]'
})
export class IconDirective {

  @Input('dataIcon') set iconName(value: string) {
    this.renderer.setAttribute(this.el.nativeElement, 'data-icon', value);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}
