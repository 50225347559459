// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDTQhMLSCm4Y5qI4miIsgvxHd8P8M5u1Hc",
    authDomain: "dev-guideglare.firebaseapp.com",
    projectId: "dev-guideglare",
    storageBucket: "dev-guideglare.appspot.com",
    messagingSenderId: "1078859510516",
    appId: "1:1078859510516:web:d665a9ad1b1c2f6e1664a1",
    measurementId: "G-CCFMY1LTTB"
  },
  stripe: {
    publishableKey: "pk_test_51Nws4YJg3t7KVLFtcyRdeeLVoFMEdkuM7KkU7zEHUVCp87p4dDWvu03p005wZDVOZgRHCmHchfAIgV4vjG9XhQ1n00sANy8Exa"
  },
  gcpConfig: {
    projectId: 'dev-guideglare',
    keyFilename: '/dev-guideglare-gallery.json',
  },
  logger: {
    cloudFunction: "function-dev-logger"
  },
  wsUrl: "ws://localhost:8080/api/ws",
  production: false,
  demo: 'default',
  apiUrl: 'http://localhost:8080/api',
  thumbsUrl: 'https://storage.googleapis.com/guideglare_gallery/img/'
};


export const appConstants = {
  pageSize: 10,
  previewSize: 3,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
