import {Component, Input, OnInit} from '@angular/core';
import {StatisticsService} from "../statistics.service";
import { AnimationModel } from '@syncfusion/ej2-progressbar';
import {StatisticsInfo} from "../statistics.model";


@Component({
    selector: 'app-tenant-statistics',
    templateUrl: './tenant-statistics.component.html',
    styleUrls: ['./tenant-statistics.component.scss']
})
export class TenantStatisticsComponent implements OnInit {

    public animation: AnimationModel;
    public isIndeterminate: boolean;
    protected id: string;

    @Input()
    statisticsInfo: StatisticsInfo;



    constructor(private statisticsService: StatisticsService) {
    }

    ngOnInit(): void {
        this.id = Math.random().toString(8);
        console.log("Got:"+this.statisticsInfo.title+": "+this.statisticsInfo.metric);
        this.animation = {enable: true, duration: 2000, delay: 500};
        this.isIndeterminate = true;
    }

}
