export class TagEntity {
    id: string;
    title: string;
    color: string;

    constructor(id: string, title: string, color: string) {
        this.id = id;
        this.title = title;
        this.color = color;
    }


}
export const DUMMY_TAGS: TagEntity[] = [
    new TagEntity('1', 'Technology', 'primary'),
    new TagEntity('2', 'Health', 'primary'),
    new TagEntity('3', 'Finance', 'success'),
    new TagEntity('4', 'Education', 'pink'),
    new TagEntity('5', 'Environment', 'info'),
    new TagEntity('10', 'Technology2', 'primary'),
    new TagEntity('20', 'Health2', 'primary'),
    new TagEntity('30', 'Finance2', 'primary'),
    new TagEntity('40', 'Education2', 'primary'),
    new TagEntity('50', 'Environment2', 'pink')
];


export const SELECTED: TagEntity[] = [
    new TagEntity('1', 'Technology', 'info'),
    new TagEntity('2', 'Health', 'pink'),

];
