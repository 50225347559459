import {ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {GradientButtonItem} from "./gradient-button.model";

@Component({
    selector: 'app-gradient-button',
    templateUrl: './gradient-button.component.html',
    styleUrls: ['./gradient-button.component.scss']
})
export class GradientButtonComponent {

    @Input()
    entity!: GradientButtonItem;

    @Input() height: number;
    @Input() col: number = 10;


    @Input() maxHeight: number;

    @Output()
    cardClicked: EventEmitter<any> = new EventEmitter<any>();

    isHovered: any;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity) {
            this.cdr.markForCheck();
        }
    }

    onCardClick(entity: any) {
        this.cardClicked.emit(entity.id);
    }

    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseLeave() {
        this.isHovered = false;
    }
}
