import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagEntity} from "../../../service/tags/tag.model";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {TagService} from "../../../service/tags/tag.service";


@Component({
  selector: 'app-tag-subfilter',
  templateUrl: './tag-subfilter.component.html',
  styleUrls: ['./tag-subfilter.component.scss']
})
export class TagSubfilterComponent {

  allTags: TagEntity[] = []; // Replace 'Tag' with your tag type
  @Input() selectedTags: TagEntity[] = []; // Tags currently associated with the entity
  @Output() addTagToFilter = new EventEmitter<TagEntity>();
  @Output() removeTagFromFilter = new EventEmitter<TagEntity>();
  @Output() clearTagFilter = new EventEmitter<void>();

  searchTerm: string | TagEntity = '';


  constructor(private tagService: TagService) {

  }

  ngOnInit(): void {
    if (this.allTags.length == 0) {
      this.tagService.getAllTags().subscribe(value => {
        this.allTags = value;
      });
    }
  }

  addTag(tag: TagEntity) {
    this.addTagToFilter.emit(tag);
  }

  removeTag(tag: TagEntity) {
    this.removeTagFromFilter.emit(tag);
  }

  clearTags(){
    this.clearTagFilter.emit();
  }


  search: (text$: Observable<string>) => Observable<TagEntity[]> = (text$: Observable<string>) =>
      text$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          map((term) => {
            if (term.length < 2) {
              return this.allTags.filter(tag =>
                  !this.selectedTags.some(entityTag => entityTag.id === tag.id)
              ).slice(0, 10);

            } else {
              // Filtering out tags that are already in entityTags
              return this.allTags.filter(tag =>
                  tag.title.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                  !this.selectedTags.some(entityTag => entityTag.id === tag.id)
              ).slice(0, 10);
            }
          }),
      );


  formatter = (result: TagEntity) => result.title;

  onSaveTagClick() {
    if (typeof this.searchTerm === 'string') {
      //TODO
    } else {
      this.addTag(this.searchTerm);
    }
  }

  canBeSaved(): boolean {
    return typeof this.searchTerm !== 'string';
  }
}

