import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/auth.service';
import {Router} from "@angular/router";
import {LoggingService} from "../service/logging.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private logger: LoggingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout(true);
                location.reload();
            } else if (err.status === 402) { // payment required =:-)
                this.logger.warn("Unauthorized request sent. Appropriate subscription is missing.", request, err)
                this.router.navigate(['/subscription']);
                return null;
            }

            // const error = err.error.message || err.statusText;
            return throwError(err);
        }));
    }
}
