import {Injectable} from "@angular/core";
import {IMessage, RxStomp, RxStompConfig} from "@stomp/rx-stomp";
import {Observable} from "rxjs";
import {AuthenticationService} from "../../core/service/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WSService {

    private readonly rxStomp: RxStomp;

    private config: RxStompConfig = {

        reconnectDelay: 3000,
        heartbeatIncoming: 3000,
        heartbeatOutgoing: 3000,

        debug: (msg: string): void => {
            if (!environment.production) {
                //  console.log(msg);
                //  console.log(this.rxStomp.stompClient.connectHeaders['Authorization'])
            }
        },
    };


    constructor(private authenticationService: AuthenticationService) {
        this.rxStomp = new RxStomp();
        this.config.brokerURL = environment.wsUrl;

        this.config.beforeConnect = (): Promise<void> => {
            return new Promise<void>((resolve, reject) => {
                this.authenticationService.currentUser().getIdToken().then(value => {
                    this.config.connectHeaders = {
                        authorization: `Bearer ${value}`
                    };
                    this.rxStomp.configure(this.config);
                    resolve();
                });
            });
        };
        this.initializeWebSocketConnection();
    }


    private initializeWebSocketConnection() {
        this.rxStomp.configure(this.config);
        this.rxStomp.activate();
    }

    watch(destination: string): Observable<IMessage> {
        return this.rxStomp
            .watch({destination: destination})
    }

    publish(destination: string, message: any): void {
        if(!this.rxStomp.stompClient.active){
            console.log("Activating deactivated client...")
            this.rxStomp.activate();
        }
        /*
        this.rxStomp.publish({
            destination: destination,
            body: JSON.stringify(message)
        });
*/

        this.authenticationService.currentUser().getIdToken().then(token => {
            // Add the authorization token to headers
            let headers = {
                authorization: `Bearer ${token}`,
            };

            // Send the message with token included
            this.rxStomp.publish({   destination: destination, body:JSON.stringify(message), headers });
        });


    }


    disconnect() {
        if (this.rxStomp) {
            this.rxStomp.deactivate();
        }
    }


}
