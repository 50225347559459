import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appEnterSubmit]'
})
export class EnterSubmitDirective {

  @Input() appEnterSubmit: boolean;

  @HostListener('keydown.enter', ['$event'])
  handleEnter(event: KeyboardEvent) {
    if (this.appEnterSubmit) {
      event.preventDefault();
      event.stopPropagation();
      const form = (event.target as HTMLElement).closest('form');
      if (form) {
        form.dispatchEvent(new Event('submit'));
      }
    }
  }

}
