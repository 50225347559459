import {
    AfterContentInit, AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {ImageEditorComponent, ToolbarEventArgs} from "@syncfusion/ej2-angular-image-editor";
import {ImagesService} from "../../apps/images/shared/service/images.service";
import {PaperService} from "../../apps/papers/shared/service/paper.service";
import {ImageSuggestionRequestApiDto} from "../../apps/images/shared/model/image.model";
import {FilesService} from "../../apps/files/shared/service/files.service";
import {WSService} from "../service/wsservice.service";
import {Subscription} from "rxjs";
import {EventService} from "../../core/service/event.service";
import {ImageHistoryService} from "../../apps/images/shared/service/image-history.service";

@Component({
    selector: 'app-ai-img-editor',
    templateUrl: './ai-img-editor.component.html',
    styleUrls: ['./ai-img-editor.component.scss']
})
export class AiImgEditorComponent implements AfterViewInit, OnDestroy {

    @Input()
    height?: string;

    @Input()
    componentId!: string;

    @Input()
    paperId?: string;

    isLayerEditing: boolean = false;

    @Input()
    editedImage: any;

    @Input()
    tempId?: string;

    @ViewChild('editor', {static: false}) editor?: ImageEditorComponent;

    @ViewChild('imageElement', {static: false})
    imageElement: ElementRef<HTMLImageElement>;

    @Output()
    suggestionStarted: EventEmitter<any> = new EventEmitter();

    @Input()
    loaded?: boolean;

    @Input()
    isTemporary?: boolean = true;

    @Input()
    isEditing?: boolean = false;

    @Input("suggestionRequest")
    set suggestionRequest(val: any) {
        this.loaded = false;
        this.editedImage = undefined;
        this.wsService.publish("/app/images", val);
    }


    public tools: any[] = [
        'Annotate',
        'Arrow',
        'Bevel',
        'Blur',
        'Brightness',
        'Chrome',
        'CircleSelection',
        'Cold',
        'Contrast',
        'Crop',
        'CustomSelection',
        'Default',
        'Ellipse',
        'Exposure',
        'Filter',
        'Finetune',
        'FlipHorizontal',
        'FlipVertical',
        'Frame',
        'Grayscale',
        'Hook',
        'HorizontalFlip',
        'Hue',
        'Image',
        'Inset',
        'Invert',
        'Line',
        'Mat',
        'Move',
        'None',
        'Opacity',
        'Pan',
        'Path',
        'Pen',
        'RatioSelection',
        'Rectangle',
        'Redo',
        'Reset',
        'Resize',
        'RotateLeft',
        'RotateRight',
        'Saturation',
        'Sepia',
        'SquareSelection',
        'Straightening',
        'Text',
        'Transform',
        'Undo',
        'VerticalFlip',
        'Warm',
        'ZoomIn',
        'ZoomOut',
        {
            id: 'done',
            text: 'Done',
            showAlwaysInPopup: true,
            visible: true,
            overflow: 'Show',
            tooltipText: 'Finish editing',
        }
    ];
    private subscription: Subscription;
    private _suggestionCommand: ImageSuggestionRequestApiDto;


    constructor(private cdr: ChangeDetectorRef,
                private imageService: ImagesService,
                private imageHistoryService: ImageHistoryService,
                private paperService: PaperService,
                private filesService: FilesService,
                private wsService: WSService,
                private eventService: EventService
    ) {
    }


    ngAfterViewInit(): void {
        if (!this.editedImage) {
            this.imageHistoryService.getCurrentImage$(this.componentId).subscribe(value => {
                this.editedImage = value;
            })
        }
        this.openStoredImage();
        this.imageService.observeImageSuggestion(this.componentId).subscribe(value => {
            this._suggestionCommand = value;
            this.loaded = false;
            this.editedImage = undefined;
            this.wsService.publish("/app/images", this._suggestionCommand);
        })

        if (!this.paperId) {
            this.subscription = this.wsService.watch("/private/images").subscribe(message => {
                const value = JSON.parse(message.body);
                console.log("Message:" + message);
                this._suggestionCommand = undefined;
                this.tempId = value.tempId;
                this.imageService.fetchImageAsBase64(value.url).subscribe({
                    next: (base64String) => {
                        base64String.then((val) => {
                            this.editedImage = val;
                            this.imageHistoryService.setCurrentImage(this.componentId, this.editedImage, false); //TODO tady
                            this.suggestionStarted.emit({tempId: value.tempId, img: val});
                            this.eventService.broadcast('image-generation-finished', null);
                            if (this.suggestionRequest) {
                                this.subscription.unsubscribe();
                            }
                            this.loaded = true;
                        });
                    },
                    error: (error) => {
                        console.error('Failed to load image:', error);
                    }
                });
            })
        }
    }

    openStoredImage(): void {
        if (this.editedImage) {
            console.log("Image is loaded.");
           // this.editedImage = this.getBase64Image();
            this.imageHistoryService.setCurrentImage(this.componentId, this.editedImage, true);
            this.loaded = true;
            return;
        } else if (this.paperId) {
            this.isTemporary = false;
            this.filesService.getFile(this.paperId).subscribe(value => {
                this.imageService.fetchImageAsBase64(value.shareKey).subscribe({
                    next: (base64String) => {
                        base64String.then((val) => {
                            this.editedImage = val;
                            this.imageHistoryService.setCurrentImage(this.componentId, this.editedImage, true);
                        });

                        this.loaded = true;
                    },
                    error: (error) => {
                        console.error('Failed to load image:', error);
                    }
                });
            })
        }
    }

    onToolbarClicked(event: ToolbarEventArgs) {
        console.log("On Before Save clicked ", event)
        if (event.item.id == "done") {
            this.editedImage = this.getBase64Image();
            this.isEditing = false;
            this.imageHistoryService.setCurrentImage(this.componentId, this.editedImage, false);
        }
    }

    handleEditButton() {
        console.log("edit button");
        let imageData = undefined;
        if (this.imageElement) {
            imageData = this.imageElement.nativeElement.src;
        }
        this.isEditing = true;
        this.cdr.detectChanges();
        if (this.editedImage && this.editor) {
            this.editor.created.subscribe(() => {
                this.editor.open(imageData);
            })
        }
    }

    handleCopyButton() {
        this.createCanvasFromImage((blob) => {
            if (blob) {
                navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob
                    })
                ]).then(() => {
                    console.log('Image copied to clipboard successfully!');
                }).catch(err => {
                    console.error('Error copying image to clipboard', err);
                });
            }
        });
    }

    handleDownloadButton() {
        this.createCanvasFromImage((blob) => {
            if (blob) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'image.png'; // you can set the file name here
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }
        });
    }

    createCanvasFromImage(callback: (blob: Blob | null) => void) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = this.imageElement.nativeElement;

        // Set canvas size equal to the image size
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Draw the image into the canvas
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

        // Convert the canvas to a Blob and pass it to the callback
        canvas.toBlob(callback, 'image/png');
    }

    handleAddLibraryButton() {
        this.isTemporary = false;
        if (this.tempId) {
            this.paperService.createPaper({paperId: this.tempId, type: 'IMG'}).subscribe(value => {
                this.tempId = undefined;
            })
        }
    }


    handleDownloadLocally() {
        const link = document.createElement('a');
        link.href = this.getBase64Image();
        link.download = 'downloaded-image.png'; // The name of the file that will be downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    private getBase64Image(): string {
        let imageData = this.editor.getImageData();
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        ctx.putImageData(imageData, 0, 0);
        return canvas.toDataURL();
    }

    private getImageData(img: HTMLImageElement): any {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        context.drawImage(img, 0, 0);
        // const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        // Convert the canvas content to a Base64 string
        return canvas.toDataURL();  // By default, this produces a PNG image

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


}
