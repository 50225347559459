import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[dataWidth]'
})
export class IconWidthDirective {

  @Input('dataWidth') set iconName(value: number) {
    this.renderer.setAttribute(this.el.nativeElement, 'data-width', String(value));
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

}
