import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TagEntity} from "../../../service/tags/tag.model";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {TagService} from "../../../service/tags/tag.service";
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'app-tag-popover',
    templateUrl: './tag-popover.component.html',
    styleUrls: ['./tag-popover.component.scss']
})
export class TagPopoverComponent implements OnInit {
    allTags: TagEntity[] = []; // Replace 'Tag' with your tag type
    @Input() entityTags: TagEntity[] = []; // Tags currently associated with the entity
    @Output() addTagToEntity = new EventEmitter<TagEntity>();
    @Output() removeTagFromEntity = new EventEmitter<TagEntity>();
    @Output() editTag = new EventEmitter<TagEntity>();

    searchTerm: string | TagEntity = '';
    editMode: { [tagId: string]: boolean } = {};


    constructor(private tagService: TagService) {

    }

    ngOnInit(): void {
        if (this.allTags.length == 0) {
            this.tagService.getAllTags().subscribe(value => {
                this.allTags = value;
            });
        }
    }

    /*    get filteredTags() {
            return this.searchTerm.length >= 3
                ? this.allTags.filter(tag =>
                    tag.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                )
                : [];
        }*/

    toggleEditMode(tag: TagEntity) {
        this.editMode[tag.id] = !this.editMode[tag.id];
    }

    saveEdit(tag: TagEntity, newTitle: string) {
        const editedTag = {...tag, title: newTitle};
        this.editTag.emit(editedTag);
        this.toggleEditMode(tag);
    }

    addTag(tag: TagEntity) {
        this.addTagToEntity.emit(tag);
    }

    removeTag(tag: TagEntity) {
        this.removeTagFromEntity.emit(tag);
    }
/*
    search: (text$: Observable<string>) => Observable<TagEntity[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) =>
                term.length < 2 ? [] : this.allTags.filter((v) => v.title.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
            ),
        );
*/

    search: (text$: Observable<string>) => Observable<TagEntity[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) => {
                if (term.length < 2) {
                    return this.allTags.filter(tag =>
                        !this.entityTags.some(entityTag => entityTag.id === tag.id)
                    ).slice(0, 10);

                } else {
                    // Filtering out tags that are already in entityTags
                    return this.allTags.filter(tag =>
                        tag.title.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                        !this.entityTags.some(entityTag => entityTag.id === tag.id)
                    ).slice(0, 10);
                }
            }),
        );


    formatter = (result: TagEntity) => result.title;

    onSaveTagClick() {
        if (typeof this.searchTerm === 'string') {
            let tag = new TagEntity(uuidv4(), this.searchTerm, 'primary');
            this.tagService.createTag(tag).subscribe(value => {
                this.addTag(tag);
            })
        } else {
            this.addTag(this.searchTerm);
        }
    }

    canBeSaved(): boolean {
        if (typeof this.searchTerm === 'string') {
            return this.searchTerm.length > 2;
        } else {
            return true;
        }
    }
}

