<div class="row ms-1 me-1 mt-2">
    <div class="row mb-2">
        <div class="col-12">
            <ng-container *ngIf="command.cmd.command == 'CUSTOM_ACTION'">
                <h4>{{ 'ai.editor.custom-action.title' | translate }}</h4>

                <textarea [(ngModel)]="content" (keydown.enter)="onEnter($event)" class="form-control me-2"
                          placeholder="{{'ai.editor.custom-action.input.placeholder' | translate }}"></textarea>
            </ng-container>
            <ng-container *ngIf="command.cmd.command == 'CHANGE_TONE'">
                <h4>{{ 'ai.editor.custom-action.tone-title' | translate }}</h4>

                <textarea [(ngModel)]="content" (keydown.enter)="onEnter($event)" class="form-control me-2"
                          placeholder="{{'ai.editor.custom-action.input.tone-placeholder' | translate }}"></textarea>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-outline-primary float-end"
                    (click)="sendMessage()">  <span class="iconify"
                                                    data-height="24px"
                                                    data-width="24px"
                                                    data-icon="line-md:telegram"></span>
            </button>
        </div>
    </div>

    <div class="row mt-3" *ngIf="command.cmd.command == 'CHANGE_TONE'">
        <h5>{{ 'ai.editor.custom-action.tone.title' | translate }}</h5>
        <div class="col-12">
            <ul>
                <li>{{ 'ai.editor.custom-action.tone.e.friendly' | translate }}</li>
                <li>{{ 'ai.editor.custom-action.tone.e.professional' | translate }}</li>
                <li>{{ 'ai.editor.custom-action.tone.e.warm' | translate }}Warm</li>
                <li>{{ 'ai.editor.custom-action.tone.e.friendly' | translate }}Encouraging</li>
                <li>{{ 'ai.editor.custom-action.tone.e.encouraging' | translate }}Optimistic</li>
                <li>{{ 'ai.editor.custom-action.tone.e.humorous' | translate }}Humorous</li>
                <li>{{ 'ai.editor.custom-action.tone.e.urgent' | translate }}Urgent</li>
                <li>{{ 'ai.editor.custom-action.tone.e.inspirational' | translate }}Inspirational</li>
            </ul>
        </div>
        <p><a href="#">{{ 'common.help.question' | translate }}</a></p>
    </div>


</div>
