import {inject, Injectable} from '@angular/core';
import {Functions, httpsCallable} from "@angular/fire/functions";
import {environment} from "../../../environments/environment"

/**
 * Logging Service providing a logging feature.
 */
@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    private functions = inject(Functions);
    callable = httpsCallable(this.functions, environment.logger.cloudFunction);


    /**
     * Logs an INFO message with optional additional data.
     * Usage:
     * ```typescript
     * this.logger.info("Some message", {param1: "value1"}, {param2: "value2"})
     * ```
     * @param {string} message - The log message to be sent.
     * @param {Object[]} [otherData] - Optional additional data to be included with the log message.
     */
    info(message: string, ...otherData: Object[]) {
        this.sendLog({level: 'INFO', message, otherData});
    }

    /**
     * Logs a DEBUG message with optional additional data.
     * Usage:
     * ```typescript
     * this.logger.debug("Some message", {param1: "value1"}, {param2: "value2"})
     * ```
     * @param {string} message - The log message to be sent.
     * @param {Object[]} [otherData] - Optional additional data to be included with the log message.
     */
    debug(message: string, ...otherData: Object[]) {
        this.sendLog({level: 'DEBUG', message, otherData});
    }

    /**
     * Logs an ERROR message with optional additional data.
     * Usage:
     * ```typescript
     * this.logger.error("Some message", {param1: "value1"}, {param2: "value2"})
     * ```
     * @param {string} message - The log message to be sent.
     * @param {Object[]} [otherData] - Optional additional data to be included with the log message.
     */
    error(message: string, ...otherData: Object[]) {
        this.sendLog({level: 'ERROR', message, otherData});
    }

    /**
     * Logs a WARN message with optional additional data.
     * Usage:
     * ```typescript
     * this.logger.warn("Some message", {param1: "value1"}, {param2: "value2"})
     * ```
     * @param {string} message - The log message to be sent.
     * @param {Object[]} [otherData] - Optional additional data to be included with the log message.
     */
    warn(message: string, ...otherData: Object[]) {
        this.sendLog({level: 'WARNING', message, otherData});
    }

    private sendLog(logEntry: LogEntry) {
        const logData = {
            logLevel: logEntry.level,
            logMessage: logEntry.message,
            context: logEntry.otherData // Any additional data you want to pass
        };
        this.callable(logData)
            .then(value => {
                if (environment.production) {
                    console.log(value)
                }
            })
            .catch(reason => {
                if (environment.production) {
                    console.log(reason)
                }
            });
    }


}

/**
 * Represents a log entry.
 *
 * @interface LogEntry
 */
interface LogEntry {
    level: 'INFO' | 'DEBUG' | 'ERROR' | 'WARNING'; // Restrict log levels to specific values
    message: string;
    otherData?: Object; // Optional additional data
}
