import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[dataHeight]'
})
export class AngularHeightDirective {

  @Input('dataHeight') set iconName(value: number) {
    this.renderer.setAttribute(this.el.nativeElement, 'data-height', String(value));
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

}
