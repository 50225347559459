<div class="">
    <div class="">

        <div class="col-md-12 col-xxl-6">
            <app-gradient-button [height]="50" [entity]="{icon: 'line-md:chat', title: 'chat.list.button.new'}"
                                 (cardClicked)="this.entityCreated.emit(); this.selectedEntity = undefined;">
            </app-gradient-button>
        </div>

        <div class="row mb-2">
            <tbody class="col">
            <app-entity-filter *ngIf="!isSimpleFilter"
                               [entityName]="entityName"
                               [parentId]="parentId"
                               (filter)="onFilterChanged($event)"
            ></app-entity-filter>
            <app-entity-simple-filter *ngIf="isSimpleFilter"
                                      (filter)="onFilterChanged($event)"
            >

            </app-entity-simple-filter>
            </tbody>
        </div>

        <ngx-simplebar class="chat-messages flex-grow-1 overflow-auto">
            <div class="table-responsive">
                <div *ngIf="loading">
                    <span class="iconify" data-width="24" data-height="24"
                          data-icon="svg-spinners:270-ring-with-bg"></span>
                </div>

                <table *ngIf="!loading" class="table table-hover table-selectable animate__fadeIn animate__animated">
                    <tbody>
                    <tr class="parent" *ngFor="let entity of entityList"
                        (click)="onSelectedEntity(entity)"
                        [class.selected]="selectedEntity === entity">

                        <div class="row me-0 pe-0">
                            <div class="col-11">
                                <span class="text-primary">{{ entity.title }}</span>
                            </div>
                            <!--    <app-tag
                                    [parentId]="entity.id!"
                                    [parentType]="taggable"
                                    [entityTagsUuids]="entity.tags!"
                            ></app-tag> -->

                            <div class="col-1" (click)="$event.stopPropagation()">
                                <span class="delete-button" (click)="onDeleteEntity(entity.id!)">
                            <span class="iconify-inline" data-width="18" data-height="18"
                                  data-icon="line-md:menu-to-close-alt-transition">
                            </span>
                        </span>
                            </div>

                        </div>
                    </tr>
                    <tr *ngIf="this.totalItems > 0">
                        <td>
                            <button
                                    type="button"
                                    class="btn btn-outline-primary waves-effect waves-light"
                                    (click)="loadMoreEntities()">{{ 'common.buttons.load-more' | translate }}
                                ({{ this.totalItems }})
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ngx-simplebar>

        <!--
        <div class="row" *ngIf="this.totalItems > 0">
            <button
                    type="button"
                    class="btn btn-outline-primary waves-effect waves-light"
                    (click)="loadMoreEntities()">Load More ({{ this.totalItems }})
            </button>
        </div> -->
    </div> <!-- end card-body-->
</div> <!-- end card-->
