<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick(entity)"
     (mouseenter)="onMouseEnter(entity)"
     (mouseleave)="onMouseLeave(entity)"
     [ngClass]="{'card-hover': isHovered}"
>
    <div class="hovno animate__fadeIn animate__animated">
        <div class="card-body">
            <div class="main-content">
                <div class="row">
                    <div class="text-md-end" [class.hidden]="!isHovered">
                        <!-- TODO XHIDDEN TAGS
                        <app-tag *ngIf="!(entity.tags || (entity.tags != null && entity.tags.length > 0))"
                                 [parentId]="entity.id!"
                                 [parentType]="taggable"
                                 [entityTagsUuids]="entity.tags!"
                        ></app-tag>
                        -->
                        <span (click)="onDeleteClick($event)">
                        <span class="iconify-inline" data-width="24" data-height="24"
                              data-icon="line-md:menu-to-close-alt-transition" color="#ff0080"></span>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="pt-2 pb-2">
                        <h4 class="mt-3">{{ entity.title }}</h4>
                        <p>{{ entity.description }}</p>
                    </div>
                </div>

                <!-- https://storage.googleapis.com/guideglare_gallery/img/9f31ff63-3ced-4119-b98b-bcbc3a74146f/73f7ffa4-caa6-49a2-a65a-2da5ccb32075_thumb -->

                <!-- TODO XHIDDEN TAGS
                <div class="row">
                    <app-tag *ngIf="entity.tags || (entity.tags != null && entity.tags.length > 0)"
                             [parentId]="entity.id!"
                             [parentType]="taggable"
                             [entityTagsUuids]="entity.tags!"
                    ></app-tag>
                </div>
                -->
            </div>
            <div class="row mt-1" *ngIf="taggable != TaggableType.SYSTEM">
                <div class="col-12 text-start">
                      <span class="iconify-inline"
                            data-icon="mdi:clock-edit-outline"></span>
                    {{ entity.modificationDate | date:'dd.MM.yyyy HH:mm' }}
                </div>
            </div>
        </div>
    </div>
</div>
