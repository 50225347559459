<div class="row mb-2 ms-auto d-flex justify-content-end" *ngIf="!isPreviewOnly && type != 'IMG'">
    <div class="col-lg-3 col-md-6" *ngIf="isSimpleFilter">
        <app-entity-simple-filter
                (filter)="onSimpleFitlerChanged($event)"
        >
        </app-entity-simple-filter>
    </div>
    <div class="col-lg-3 col-md-6" *ngIf="!isSimpleFilter">
        <app-entity-filter
                [entityName]="entityName"
                [parentId]="parentId"
                (filter)="onFilterChanged($event)"
                [type]="type"
        ></app-entity-filter>

    </div>
</div>

<div class="row">
    <div [class]="'col-xl-'+xxlCol+1+' col-xxl-'+xxlCol+' col-lg-' + lgCol + ' col-md-' + mdCol"
         *ngIf="isEmptyCard || (isPreviewOnly && this.currentPage > 0 && this.entityList.length == 0)">
        <app-empty-card (cardClicked)="onNewCard()"></app-empty-card>
    </div>

    <div [class]="'col-xl-'+xxlCol+1+' col-xxl-'+xxlCol+' col-lg-' + lgCol + ' col-md-' + mdCol" *ngFor="let entity of entityList">
        <app-entity-card *ngIf="type != 'IMG' && type != 'TEMPLATE'"
                         [entity]="entity"
                         [taggable]="taggable"
                         (cardClicked)="onSelectedEntity($event)"
                         (deleteClicked)="onDeleteEntity(entity.id)"
        ></app-entity-card>
        <app-gallery-card *ngIf="type == 'IMG'"
                          [entity]="entity"
                          [taggable]="taggable"
                          (cardClicked)="onSelectedEntity($event)"
                          (deleteClicked)="onDeleteEntity(entity.id)"
        ></app-gallery-card>
        <app-template-card *ngIf="type == 'TEMPLATE'"
                           [entity]="entity"
                           [taggable]="taggable"
                           (cardClicked)="onSelectedEntity($event)"
                           (deleteClicked)="onDeleteEntity(entity.id)"
        ></app-template-card>
    </div>

    <div [class]="'col-xl-'+xxlCol+1+' col-xxl-'+xxlCol+' col-lg-' + lgCol + ' col-md-' + mdCol" *ngIf="this.totalItems > 0 && !isPreviewOnly">
        <app-load-card [remainingEntities]="totalItems" (cardClicked)="loadMoreEntities()"></app-load-card>
    </div>
    <div [class]="'col-xl-'+xxlCol+1+' col-xxl-'+xxlCol+' col-lg-' + lgCol + ' col-md-' + mdCol" *ngIf="isPreviewOnly && this.entityList.length != 0">
        <app-load-card [remainingEntities]="-1" (cardClicked)="navigateToRoute()"></app-load-card>
    </div>

</div>

