<div class="row" *ngIf="!this.isCompleted">
    <span class="iconify" data-width="24" data-height="24" data-icon="svg-spinners:270-ring-with-bg"></span>
</div>

<div class="row" *ngIf="this.isCompleted">
    <div class="col-12">
        <div class="editor-container">
            <ejs-documenteditorcontainer #mainEditor
                                         [enableToolbar]=true
                                         (created)="onCreate()"
                                         [toolbarItems]="items"
                                         (contentChange)="onDocumentChange()"
                                         (toolbarClick)="onToolbarClick($event)"
                                         [height]="calcMaxHeight()"
                                         [serviceUrl]="hostUrl"
                                         style="display:block;"
                                         (selectionChange)="onSelectionChanged($event)"
            >
            </ejs-documenteditorcontainer>
        </div>
    </div>
</div>

<button *ngIf="buttonState == 'saved'" class="btn btn-success"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;">
    <span class="iconify" data-height="24px" data-width="24px"
          data-icon="line-md:check-all"></span> {{ 'advanced-editor.save.success' | translate }}
</button>

<button *ngIf="buttonState == 'waiting'" class="btn btn-info"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;"

        (mouseenter)="waitHover = true"
        (mouseleave)="waitHover = false"
        (click)="forceUpload()">
    <div *ngIf="countdownValue > 0">
        <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:uploading-loop"></span> <span
            *ngIf="!waitHover"> {{ 'advanced-editor.save.timeout' | translate }} {{ countdownValue }}s</span>
        <span *ngIf="waitHover">{{ 'advanced-editor.save.instantly' | translate }}</span>
    </div>

    <div *ngIf="countdownValue < 1">
        <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:cloud-up"></span>
    </div>
</button>

<button *ngIf="buttonState == 'error'" class="btn btn-danger"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;">
    <span class="iconify" data-height="24px" data-width="24px"
          data-icon="line-md:cloud-off-outline-loop"></span> {{ 'advanced-editor.save.timeout' | translate }} {{ countdownValue }}
    s
</button>


<!-- isDialog: boolean, isLocal: boolean, command: string, context: any -->
