<h4>{{statisticsInfo.title}}</h4>
<div class="row">
    <div class="col">
        <ejs-progressbar *ngIf="this.statisticsInfo.metric"
                [id]="id"
                type='Circular'
                height='60'
                [value]="this.statisticsInfo.metric"
                [showProgressValue]="true"
                [animation]='animation'>
        </ejs-progressbar>
    </div>
</div>
