<div class="position-relative">
    <app-ui-preloader [display]="!loaded" [message]="'preloader.image.generation'"></app-ui-preloader>

    <div id="wrapperDiv" *ngIf="isEditing">

        <ng-container *ngIf="loaded">
            <ng-container *ngIf="isEditing">
                <ejs-imageeditor #editor
                                 [showQuickAccessToolbar]="true"
                                 (toolbarItemClicked)="onToolbarClicked($event)"
                                 [toolbar]="tools">
                </ejs-imageeditor>
            </ng-container>
        </ng-container>

    </div>
    <ng-container *ngIf="loaded && !isEditing">
        <ng-container *ngIf="editedImage">
            <div class="image-container">
                <img #imageElement [src]="editedImage" [height]="height" alt="Generated content">
                <div class="overlay"></div>
                <div class="button-container">
                    <div class="row">
                        <div class="col">
                            <button class="hover-btn btn btn-outline-primary" (click)="handleEditButton()">
                                <span class="iconify"
                                      data-icon="mdi:edit-outline"></span> {{ 'image.editor.edit' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="hover-btn btn btn-outline-primary" (click)="handleCopyButton()">
                                <span class="iconify" data-icon="mdi:content-copy"></span>
                                {{ 'image.editor.clipboard.copy' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="hover-btn btn btn-outline-primary"
                                    (click)="handleDownloadButton()">
                                <span class="iconify" data-icon="mdi:file-download-outline"></span>
                                {{ 'image.editor.download' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button *ngIf="isTemporary" class="hover-btn btn btn-outline-primary"
                                    (click)="handleAddLibraryButton()">
                                <span class="iconify" data-icon="mdi:content-save-plus-outline"></span>
                                {{ 'image.editor.library.add' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
