import {MenuItem} from '../models/menu.model';

// menu items for vertcal and detached layout
const MENU_ITEMS: MenuItem[] = [

    {
        key: 'apps-dashboard',
        label: 'MENU.Dashboard',
        isTitle: false,
        icon: 'mdi-monitor-dashboard',
        collapsed: true,
        link: '/dashboard',
    },
    {
        key: 'apps-papers',
        label: 'MENU.Documents',
        isTitle: false,
        icon: 'mdi-file-outline',
        collapsed: false,
        children: [
            {
                key: 'papers-list',
                label: 'MENU.AirDocs',
                link: '/papers/air',
                parentKey: 'apps-papers',
                icon: 'tabler:air-balloon'
            },
            {
                key: 'doc-list',
                label: 'MENU.CoreDocs',
                link: '/papers/core',
                parentKey: 'apps-papers',
                icon: 'tabler:news'
            },
        ],
    },

    {
        key: 'apps-images',
        label: 'MENU.Images',
        isTitle: false,
        icon: 'mdi-image-multiple-outline',
        collapsed: false,
        link: '/images',
    },
    {
        key: 'apps-chats',
        label: 'MENU.Chats',
        isTitle: false,
        icon: 'mdi-chat-bubble-outline',
        collapsed: false,
        link: '/chats',
    },
    {
        key: 'apps-templates',
        label: 'MENU.Templates',
        isTitle: false,
        icon: 'mdi-invoice-edit-outline',
        collapsed: false,
        link: '/templates',
    },
    /*
    {
        key: 'apps-spreadsheets',
        label: 'Spreadsheets',
        isTitle: false,
        icon: 'mdi-image-multiple-outline',
        collapsed: true,
        link: '/spreadsheets',
    },*/
    /* TODO XHIDDEN CHATLIST
    {
        key: 'apps-checklists',
        label: 'Checklists',
        isTitle: false,
        icon: 'mdi-format-list-checks',
        collapsed: true,
        link: '/checklists',
    },*/
    {
        key: 'apps-misc',
        label: 'MENU.Misc',
        isTitle: false,
        icon: 'fluent:group-24-filled',
        collapsed: true,
        children: [
            /* TODO XHIDDEN TAGS
            {
                key: 'tags-list',
                label: 'Manage tags',
                link: '/misc/tags',
                parentKey: 'apps-misc' ,
                icon: 'icons8:price-tag'
            },
            */

            {
                key: 'feedback-list',
                label: 'MENU.SendFeedback',
                link: '/misc/feedback',
                parentKey: 'apps-misc',
                icon: 'tabler:message-heart'
            },
            {
                key: 'subscriptions',
                label: 'MENU.MySubscription',
                link: '/subscription',
                parentKey: 'apps-misc',
                icon: 'tabler:chart-candle'
            },
            {
                key: 'invoices',
                label: 'MENU.MyInvoices',
                link: '/subscription/invoices',
                parentKey: 'apps-misc',
                icon: 'tabler:report-money'
            },
        ],
    },
];

export {MENU_ITEMS};
