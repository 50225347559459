import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { EventService } from '../core/service/event.service';
import { LAYOUT_VERTICAL} from './shared/config/layout.model';
import { getLayoutConfig } from './shared/helper/utils';
import { LayoutConfig } from './shared/models/layout-config.model';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss']
})
export class LayoutContainerComponent implements OnInit, AfterViewInit {

  // layout related config
  layoutType!: string;
  layoutColor!: string;
  layoutConfig!: LayoutConfig;
  layoutWidth!: string;
  configuredDemo: string = "default";

  constructor (private eventService: EventService) { }

  ngOnInit(): void {
    // default settings
    this.configuredDemo = environment.demo;

    // tslint:disable-next-line: max-line-length
    this.layoutType = LAYOUT_VERTICAL;
    this.setLayoutConfig();

    // listen to event and change the layout configuarations
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
      this.setLayoutConfig();
    });

    this.eventService.subscribe('changeLayoutColor', (color) => {
      setTimeout(() => {
        this.layoutColor = color;
      }, 20);
    });

  }

  ngAfterViewInit(): void {
    document.body.classList.remove('authentication-bg', 'authentication-bg-pattern', 'auth-fluid-pages', 'pb-0');
  }

  /**
   * set layout config
   */
  setLayoutConfig(): void {
    this.layoutConfig = getLayoutConfig(this.layoutType);
    this.layoutWidth = this.layoutConfig.layoutWidth;
    this.layoutColor = this.layoutConfig.layoutColor;
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }



}
