<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick()"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [ngClass]="{'card-hover': isHovered}"
>
    <div class="hovno animate__fadeIn animate__animated">
        <ng-container *ngIf="remainingEntities > -1">
            <h4>+ {{ remainingEntities }}</h4>
            <div *ngIf="isHovered" class="animate__fadeIn animate__animated">
            <span class="iconify" data-width="48" data-height="48" data-icon="line-md:chevron-small-triple-right"
                  style="color: #6658dd"></span>
            </div>
            <div *ngIf="!isHovered" class="animate__fadeIn animate__animated">
                <span class="iconify" data-width="48" data-height="48" data-icon="mdi:chevron-triple-right"></span>
            </div>
        </ng-container>
        <ng-container *ngIf="remainingEntities == -1">
            <span class="iconify" data-width="48" data-height="48" data-icon="mdi:chevron-triple-right"></span>
        </ng-container>

    </div>

</div>
