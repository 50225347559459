import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Paper} from "../../../apps/papers/shared/paper.model";

@Injectable({
    providedIn: 'root'
})
export class EnumerationService {

    private apiUrl = environment.apiUrl + '/enumerations';


    constructor(private http: HttpClient) {
    }


    public getEnumeration(enumerationId: string, langId: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/${enumerationId}/${langId}`);
    }

}

export const ENUM_LANGUAGES = "languages";
export const ENUM_TONES = "tones";
