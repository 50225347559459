import {Injectable, NgZone} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {CreatePaper, Paper, PaperCommand, PaperCommandResult, PaperTemplate} from "../paper.model";
import {map} from "rxjs/operators";
import {createRequestOption} from "../../../../shared/utils/request-utils";
import {AbstractEntityServiceService} from "../../../../shared/service/abstract-entity-service.service";
import {Cacheable, GlobalCacheConfig, LocalStorageStrategy} from "ts-cacheable";
import {ChecklistItem} from "../../../checklists/shared/checklist.model";

@Injectable({
    providedIn: 'root'
})
export class PaperService extends AbstractEntityServiceService<Paper> {

    private apiUrl = environment.apiUrl + '/papers';

    private currentPaper: Paper;

    private createPaperRequest: CreatePaper;

    setCurrentPaper(paper: Paper) {
        this.currentPaper = paper;
    }

    getCurrentPaper() {
        return this.currentPaper;
    }


    setCreatePaper(createPaper: CreatePaper) {
        this.createPaperRequest = createPaper;
        this.currentPaper = undefined;
    }

    getCreatePaper(): CreatePaper {
      return this.createPaperRequest;
    }


    constructor(private http: HttpClient, private zone: NgZone) {
        super(http);
        super["baseUrl"] = environment.apiUrl + '/papers';
    }

    createPaper(paper: any): Observable<Paper> {
        return this.http.post<Paper>(this.apiUrl, paper);
    }

    getPapers(page: number, size: number, req?: any): Observable<{ papers: Paper[], totalItems: number }> {
        const headers = new HttpHeaders({
            'X-Page-Number': `${page}`,
            'X-Page-Size': `${size}`
        });
        const options = createRequestOption(req);
        return this.http.get<Paper[]>(this.apiUrl, {headers: headers, params: options, observe: 'response'})
            .pipe(
                map(response => {
                    const totalItems = parseInt(<string>response.headers.get('X-Total-Count'), 10);
                    return {papers: response.body ?? [], totalItems};
                })
            );
    }

    getPaper(paperId: string): Observable<Paper> {
        console.log("Getting file: "+ paperId);
        return this.http.get<Paper>(`${this.apiUrl}/${paperId}`);
    }

    deletePaper(id: string): Observable<void> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete<void>(url);
    }

    updatePaper(paperId: string, paper: Paper): Observable<void> {
        const url = `${this.apiUrl}/${paperId}`;
        return this.http.put<void>(url, paper);
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getPaperTemplates(): Observable<PaperTemplate[]> {
        return this.http.get<PaperTemplate[]>(`${this.apiUrl}/templates`);
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy,

    })
    getPaperTemplate(paperId: string): Observable<PaperTemplate> {
        return this.http.get<PaperTemplate>(`${this.apiUrl}/templates/${paperId}`);
    }

    getPaperContent(id: string): Observable<string> {
        return new Observable<string>((observer) => {
            const eventSource = new EventSource(`${this.apiUrl}/${id}/content`);
            eventSource.onmessage = (event) => {
                console.log(event.type);

                this.zone.run(() => {
                    observer.next(event.data)
                })
            };
            eventSource.onerror = (error) => {
                eventSource.close();
            };
            return () => eventSource.close();
        });
    }


    suggestPaperContent(paperId: string, paperTemplate: any): Observable<string> {
        return new Observable<string>((observer) => {
            const eventSource = new EventSource(`${this.apiUrl}/${paperId}/content`);
            eventSource.onmessage = (event) => {
                console.log(event.type);

                this.zone.run(() => {
                    observer.next(event.data)
                })
            };
            eventSource.onerror = (error) => {
                eventSource.close();
            };
            return () => eventSource.close();
        });
    }

    performCommandOnPaper(paperId: string, dto: PaperCommand): Observable<PaperCommandResult> {
        const url = `${this.apiUrl}/${paperId}/command`;
        return this.http.post<PaperCommandResult>(url, dto);
    }


}
