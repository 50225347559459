import {Validators} from "@angular/forms";

export class Paper {
    id?: string;
    title: string;
    description?: string;
    content?: string;
    type?: string;
    tags?: string[];
    templateId?: string;
    modificationDate?: Date;
    creationDate?: Date;
    userTemplate?: boolean;


    constructor(title: string) {
        this.title = title;
    }
}

export class PaperRequest {
    id: string;
    title: string;


    constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
    }
}

export class PaperCommand {
    id: string;
    command: string;
    content?: string;
    options?: any;

    constructor(id: string, command: string, content?: string, options?: any) {
        this.id = id;
        this.command = command;
        this.content = content;
        this.options = options;
    }
}


export class PaperCommandResult {
    id?: string;
    content: string;
    options?: any;

    constructor(content: string) {
        this.content = content;
    }
}

export enum CommandType {
    IMG = 'IMG',
    TXT = 'TXT',
    IMG_LIBRARY = 'IMG_LIBRARY',
    HTML = 'HTML',
    CUSTOM = 'CUSTOM',
}

export class CommandWrapper {
    id: number;
    header: any;
    cmd: PaperCommand;
    text: string = "";
    parsedText: string = "";
    payload?: any;
    isCompleted: boolean = false;
    type: CommandType

    public constructor(id: number) {
        this.id = id;
    }
}


export interface PaperTemplate {
    id?: string;
    title?: string;
    image?: CategoryLinks;
    description?: string;
    category?: Category;

    formFields: TemplateFormField[]

}

export interface UserTemplate {
    id: string;
    templateName: string;
    templateDescription: string,
    iconKey: string,
    includeLang: boolean,
    includeStyle: boolean,
    includeLength: boolean,
    fields: TemplateFormField[];
    templateQuery: string;
}

export interface SystemTemplate {
    id: string;
    templateName: string;
    templateDescription: string,
    iconKey: string,
    includeLang: boolean,
    includeStyle: boolean,
    includeLength: boolean,
    fields: TemplateFormField[];
}

export interface TemplateFormField {

    name: string;
    title: string;
    type: string;
    required: boolean;
    fieldDescription?: string;
    options?: string[]; // For selection type
    optionsRaw?: string;
    inputType?: 'text' | 'number' | 'email'; // For input type
}

import {v4 as uuidv4} from "uuid";

export class CreatePaper {

    title: string;
    paperId: string;
    templateId: string;
    data: any;
    type: 'HMTL' | 'IMG' | 'SFDT';
    suggest: boolean;
    userTemplate: boolean;
    lang?: string;

    constructor(title: string, templateId: string, data: any) {
        this.title = title;
        this.templateId = templateId;
        this.data = data;
        this.paperId = uuidv4();
    }
}

export interface PaperCreationBox {
    createPaper?: CreatePaper;
    paper?: Paper;

}


export enum Category {
    Social = 'Social',
    Blog = 'Blog',

    TikTok = 'TikTok',
    Instagram = 'Instagram'
}

export enum CategoryLinks {
    Social = 'assets/images/paper-templates/youtube.png',
    Blog = 'assets/images/paper-templates/youtube.png',
    Instagram = 'assets/images/paper-templates/instagram.png',
    TikTok = 'assets/images/paper-templates/youtube.png',
}
