<div *ngIf="!isEditing; else editMode">
    <h2 class="h2-title" (click)="enableEdit()">{{ currentText }}</h2>
</div>

<ng-template #editMode>

    <div *ngIf="isEditing" class="row">
        <div class="col-8 d-flex align-items-center">
            <input class="editable-input" [(ngModel)]="currentText" autofocus/>
        </div>
        <div class="col-4 d-flex align-items-center">
            <span class="me-1 iconify-success" (click)="saveEdit(currentText)">
            <span class="iconify" data-width="36" data-height="36"
                  data-icon="line-md:confirm-circle"></span>
            </span>
            <span class="me-1 iconify-cancel" (click)="cancelEdit()">
                <span class="iconify" data-width="36" data-height="36" data-icon="line-md:close-circle"></span>
            </span>
        </div>
    </div>
</ng-template>
