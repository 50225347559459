import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-enumeration-list',
    templateUrl: './enumeration-list.component.html',
    styleUrls: ['./enumeration-list.component.scss']
})
export class EnumerationListComponent {

    @Input("enumerationList")
    protected enumerationList: EnumerationItem[];

    @Output('itemSelected')
    languageSelected: EventEmitter<EnumerationItem> = new EventEmitter<EnumerationItem>();

    onItemClicked(entity: EnumerationItem) {
        this.languageSelected.emit(entity);
    }
}

export interface EnumerationItem {
    title: string;
    icon: string;
    code: string;
    selected?: boolean;
}
