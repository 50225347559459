import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TagEntity} from "./tag.model";
import {environment} from "../../../../environments/environment";
import {AbstractEntityServiceService} from "../abstract-entity-service.service";

// Define enumeration for taggable types
export enum TaggableType {
    PAPER = 'PAPER',
    TASK = 'TASK',
    GLARE = 'GLARE',
    PROJECT = 'PROJECT',
    CHECKLIST = 'CHECKLIST',
    SYSTEM = '',
    NONE='NONE'
}

@Injectable({
    providedIn: 'root'
})
export class TagService extends AbstractEntityServiceService<TagEntity>{
    private apiUrl = environment.apiUrl + '/tags';
    private apiUrlTaggables = environment.apiUrl + '/taggables';

    //private cache: { [id: string]: TagEntity } = {};

    private cache: Map<string, TagEntity> = new Map<string, TagEntity>();


    allKnownTags: TagEntity[] = [];

    constructor(private http: HttpClient) {
        super(http);
        super["baseUrl"] = environment.apiUrl + '/tags';
    }

    public getAllTags(): Observable<TagEntity[]> {
        if (this.allKnownTags.length == 0) {
           return this.http.get<TagEntity[]>(this.apiUrl)
                .pipe(
                    map(tags => {
                        // Cache fetched tags
                        tags.forEach(tag => this.cache.set(tag.id, tag));
                        this.allKnownTags.push(...tags);
                        return this.allKnownTags;
                    }),
                    catchError(error => {
                        // Handle error
                        console.error('Error fetching tags:', error);
                        return of([]);
                    })
                );
        } else {
            return of(this.allKnownTags);
        }
    }

    public getTags(tagIds: string[]): Observable<TagEntity[]> {
        // Get IDs of tags that need to be fetched from the server
        const idsToFetch = this.getMissingTagIds(tagIds);

        // If there are tags to fetch
        if (idsToFetch.length > 0) {
            return this.http.get<TagEntity[]>(`${this.apiUrl}?tagIds=${idsToFetch.join(',')}`).pipe(
                // Handle the response
                map(fetchedTags => {
                    // Cache the fetched tags
                    fetchedTags.forEach(tag => this.cache.set(tag.id, tag));

                    // Combine fetched tags with cached ones
                    return tagIds.map(id => this.cache.get(id)).filter(tag => tag !== undefined) as TagEntity[];
                }),
                catchError(error => {
                    console.error('Error fetching tags:', error);
                    return of([]); // Return an empty array in case of error
                })
            );
        } else {
            // If all tags are already cached, return them directly
            return of(tagIds.map(id => this.cache.get(id)).filter(tag => tag !== undefined) as TagEntity[]);
        }
    }


    private getMissingTagIds(tagIds: string[]): string[] {
        // Pair each id with its corresponding tag or undefined
        let idWithTagPairs = tagIds.map(id => ({id, tag: this.cache.get(id)}));

        // Filter out the pairs where the tag is undefined and extract their IDs
        return idWithTagPairs
            .filter(pair => pair.tag === undefined)
            .map(pair => pair.id);
    }


    // Create a new checklist
    createTag(checklist: TagEntity): Observable<TagEntity> {
        const url = `${this.apiUrl}`;
        return this.http.post<TagEntity>(url, checklist);
    }


    // Add tag to Taggable entity
    addTagToEntity(taggableId: string, type: TaggableType, tagId: string) {
        const url = `${this.apiUrlTaggables}/${taggableId}/type/${type}`;
        return this.http.post(url, {'tagId': tagId});
    }

    // Delete tag from Taggable entity
    deleteTagFromEntity(taggableId: string, type: TaggableType, tagId: string) {
        const url = `${this.apiUrlTaggables}/${taggableId}/type/${type}/tag/${tagId}`;
        return this.http.delete(url);
    }

}

