import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageApiDto} from "../files.model";
import {environment} from "../../../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class FilesService {

    private baseUrl = environment.apiUrl + '/files';

    constructor(private http: HttpClient) {
    }

    // POST /files - Create new file
    createFile(fileData: StorageApiDto): Observable<StorageApiDto> {
        return this.http.post<StorageApiDto>(this.baseUrl, fileData);
    }

    // GET /files/{fileId} - get file data
    getFile(fileId: string): Observable<StorageApiDto> {
        return this.http.get<StorageApiDto>(`${this.baseUrl}/${fileId}`);
    }

    // DELETE /files/{fileId} - Delete a file
    deleteFile(fileId: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${fileId}`);
    }


    // POST /files/{fileId}/url - Get file URL for write
    getFileUrl(fileId: string, type: 'WRITE' | 'READ', request: StorageApiDto): Observable<StorageApiDto> {
        return this.http.post<StorageApiDto>(`${this.baseUrl}/${fileId}/url/${type}`, request);
    }


    downloadFile(presignedUrl: string): Observable<Blob> {
        return this.http.get(presignedUrl, {responseType: 'blob'});
    }

    downloadTxt(presignedUrl: string): Observable<string> {
        return this.http.get(presignedUrl, {responseType: 'text'});
    }

    uploadFile(selectedFile: any, url: string): Observable<any> {
        const formData = new FormData();
        const customTime = new Date().toISOString(); // Current UTC time in the correct format
        formData.append('file', selectedFile);
        const headers = new HttpHeaders({
            'Custom-Time': customTime,
            'Content-Type': 'application/json'  // This should match the Content-Type used when the URL was signed
        });

        return this.http.put(url, selectedFile, {headers});
    }

}
