import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TagEntity} from "../../service/tags/tag.model";

@Component({
    selector: 'app-entity-simple-filter',
    templateUrl: './entity-simple-filter.component.html',
    styleUrls: ['./entity-simple-filter.component.scss']
})
export class EntitySimpleFilterComponent {

    @Output()
    filter: EventEmitter<string> = new EventEmitter<string>();

    isCollapsed: boolean = true;

    searchTitle: string = '';

    emitFilter(): void {
        this.filter.emit(this.searchTitle);
    }

    onEnter(event: KeyboardEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.filter.emit(this.searchTitle);
    }

    clearSearch() {
        this.searchTitle = '';
        this.filter.emit(this.searchTitle);
    }
}
