import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {CreatePaper, Paper, PaperCommand, PaperCommandResult} from "../../paper.model";
import {PaperService} from "../../service/paper.service";
import {
    CustomToolbarItemModel,
    DocumentEditorComponent,
    DocumentEditorContainerComponent, SelectionChangeEventArgs
} from "@syncfusion/ej2-angular-documenteditor";
import {StorageApiDto} from "../../../../files/shared/files.model";
import {FilesService} from "../../../../files/shared/service/files.service";
import {ClickEventArgs} from '@syncfusion/ej2-navigations'
import {AuthenticationService} from "../../../../../core/service/auth.service";
import {HttpErrorResponse} from "@angular/common/http";
import {EditorService} from "../../service/editor.service";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-advanced-editor',
    templateUrl: './advanced-editor.component.html',
    styleUrls: ['./advanced-editor.component.scss']
})
export class AdvancedEditorComponent implements OnInit {

    @Input()
    paper!: Paper;

    storage?: StorageApiDto;
    waitHover: boolean = false;
    countdownValue: number = 60;
    buttonState: 'waiting' | 'saved' | 'hidden' | 'error' = 'hidden';
    @ViewChild('mainEditor')
    public container: DocumentEditorContainerComponent;
    @Output()
    AIEditorClicked: EventEmitter<Boolean> = new EventEmitter();
    public exportDocx: CustomToolbarItemModel = {
        prefixIcon: "e-export-word",
        tooltipText: "Exports document as DOCX and downloads it.",
        text: "Export & download",
        id: "save_docx"
    };/*
    public saveManually: CustomToolbarItemModel = {
        prefixIcon: "e-export-word",
        tooltipText: "Save document into cloud",
        text: "Save",
        id: "save_gcp"
    };*/
    /*
    public showAi: CustomToolbarItemModel = {
        prefixIcon: 'e-objects',
        tooltipText: 'Show AI panel',
        text: 'AI magic',
        id: 'ai',
    }*/
    public items = [this.exportDocx, /*this.saveManually,*/ 'Separator', /*this.showAi,*/ 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'Separator', 'Find', 'Separator', /*'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'Separator'*/];
    cmdResults: PaperCommandResult[] = [
        {
            content: ''
        }
    ];

    protected hostUrl: any = environment.apiUrl+"/";
    protected readonly PaperCommand = PaperCommand;
    private saveDocumentTimer: any;
    private createPaper: CreatePaper;
    protected isCompleted: boolean = false;
    private docContent: string;

    constructor(protected paperService: PaperService,
                protected filesService: FilesService,
                protected authenticationService: AuthenticationService,
                private ngZone: NgZone,
                private cd: ChangeDetectorRef,
                private editorService: EditorService) {

    }

    public onToolbarClick(args: ClickEventArgs): void {
        console.log("event clicked" + args.item.id);
        switch (args.item.id) {
            case 'save_docx':
                //Disable image toolbar item.
                this.saveAsDocx();
                break;
            case 'ai':
                this.showAiPanel();
                break;
            case 'save_gcp':
                console.log("Save cloud clicked")
                break;
        }
    };

    ngOnInit(): void {


        this.createPaper = this.paperService.getCreatePaper();
        if (this.createPaper) {
            this.isCompleted = true;
            if (this.createPaper.suggest) {
                //this.onCreateSuggestion(); suggestion is not supported for SFDT now
            }
        } else {
            this.filesService.getFile(this.paper.id).subscribe({
                next: (value) => {
                    this.storage = value;
                    this.filesService.downloadTxt(value.shareKey).subscribe(
                        {
                            next: (sfdt) => {
                                if (sfdt && sfdt != "") {
                                    this.docContent = sfdt;
                                    // this.container.documentEditor.open(sfdt);
                                }
                                this.isCompleted = true;
                            },
                            error: (err) => {
                                console.debug("Cannot download file." + err)
                            }
                        }
                    )
                },
                error: (err) => {
                    console.debug("Cannot create file." + err)
                }
            });
        }

        if (!this.createPaper && !this.storage) {

        }


    }

    public onCreate(): void {
        this.authenticationService.currentUser().getIdToken().then(value => {
            this.container.headers = [{authorization: `Bearer ${value}`}]
        });
        if (this.container && this.docContent && this.docContent != "") {
            this.container.documentEditor.open(this.docContent);
        }
    }


    public calcMaxHeight(): string {
        return `calc(100vh - 200px)`;
    }

    /*
    onCreate() {
        if (!this.storage) {
            let request = new StorageApiDto();
            request.id = this.paper.id;
            request.parentId = this.paper.id;
            request.type = 'SFDT';

            this.filesService.createFile(request).subscribe({
                next: (value) => {
                    this.storage = value;
                    this.filesService.downloadTxt(value.shareKey).subscribe(
                        {
                            next: (sfdt) => {
                                this.container.documentEditor.open(sfdt);
                            },
                            error: (err) => {
                                console.debug("Cannot download file." + err)
                            }
                        }
                    )
                },
                error: (err) => {
                    console.debug("Cannot create file." + err)
                }
            });

        }
    }*/

    onDocumentChange() {
        this.resetTimer();
        this.setDocumentText();
    }

    resetTimer() {
        this.countdownValue = 60;
        this.buttonState = 'waiting';
        this.cd.detectChanges(); // Trigger change detection to update the view

        if (this.saveDocumentTimer) {
            clearTimeout(this.saveDocumentTimer);
        }

        // Running this outside Angular's zone to prevent performance degradation
        this.ngZone.runOutsideAngular(() => {
            this.saveDocumentTimer = setInterval(() => {
                this.countdownValue--;
                if (this.countdownValue === 0) {
                    clearInterval(this.saveDocumentTimer);
                    this.ngZone.run(() => {
                        this.saveDocumentToCloud();
                    });
                } else {
                    // Optimize change detection
                    this.ngZone.run(() => {
                        this.cd.detectChanges();
                    });
                }
            }, 1000);
        });
    }

    onSaveComplete(success: boolean) {
        if (success) {
            this.buttonState = 'saved';
        } else {
            this.buttonState = 'error';
        }
        this.cd.detectChanges();
        setTimeout(() => {
            this.buttonState = 'hidden';
            this.cd.detectChanges();
        }, 5000); // Hide after 5 seconds
    }

    saveDocumentToCloud() {
        if (this.storage) {
            (this.container.documentEditor as DocumentEditorComponent).saveAsBlob("Sfdt").then(value => {
                this.filesService.getFileUrl(this.storage.id, 'WRITE', this.storage).subscribe(url => {
                    this.filesService.uploadFile(value, url.shareKey).subscribe({
                            next: (value: Paper) => {
                                this.onSaveComplete(true);
                            },
                            error: (err: HttpErrorResponse) => {
                                this.onSaveComplete(false);
                            }
                        }
                    )
                })
            })
        }
    }

    public saveAsDocx(): void {
        //Export the document in docx format.
        (this.container.documentEditor as DocumentEditorComponent).save('sample', 'Docx');
    }

    public saveDocument(): void {
        this.onDocumentChange();
    }

    forceUpload() {
        clearInterval(this.saveDocumentTimer);
        this.countdownValue = 0;
        this.waitHover = false;
        this.saveDocumentToCloud();
    }

    onSelectionChanged(event: SelectionChangeEventArgs) {
        if (event.isCompleted && this.container.documentEditor.selection) {
            this.editorService.setSelection(this.container.documentEditor.selection.text);
        }
    }

    private showAiPanel(): void {
        this.AIEditorClicked.emit(true);
    }

    private setDocumentText(): void {
        this.container.documentEditor.saveAsBlob('Txt').then((blob: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
                const text = reader.result as string;
                this.editorService.setDocumentText(text); // Use the document text as needed
            };
            reader.readAsText(blob);
        });
    }
}
