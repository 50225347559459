import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WSService} from "../service/wsservice.service";
import {CommandWrapper} from "../../apps/papers/shared/paper.model";

@Component({
    selector: 'app-ai-custom-action',
    templateUrl: './ai-custom-action.component.html',
    styleUrls: ['./ai-custom-action.component.scss']
})
export class AiCustomActionComponent {

    @Input() command: CommandWrapper;
    content: string;

    @Output() onSent: EventEmitter<any> = new EventEmitter<any>();


    constructor(private wsService: WSService) {

    }


    onEnter($event: any) {

    }

    sendMessage() {
        this.command.cmd.options = {addition: this.content};
        this.onSent.emit(this.command);
    }
}
