import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TaggableType} from "../../service/tags/tag.service";
import {AbstractEntityServiceService} from "../../service/abstract-entity-service.service";
import {appConstants} from "../../../../environments/environment";
import {RSQLCriteria, RSQLFilterBuilder} from "rsql-criteria-typescript";
import {RsqlHelper} from "../../utils/rsql-helper";
import {Router} from "@angular/router";

@Component({
    selector: 'app-entity-cards',
    templateUrl: './entity-cards.component.html',
    styleUrls: ['./entity-cards.component.scss']
})
export class EntityCardsComponent<T> implements OnInit, OnChanges {

    @Input()
    type?: string;
    @Input()
    entityName!: string;
    @Input()
    entityService!: AbstractEntityServiceService<T>;
    @Input()
    taggable!: TaggableType;
    @Input()
    parentId?: string;
    @Input()
    isEmptyCard?: boolean = true;
    @Input()
    isPreviewOnly: boolean = false;

    @Input()
    navigationDestination?: string;

    @Input()
    isSimpleFilter: boolean = true; // TODO XHIDDEN TAGS

    @Output()
    filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    entitySelected: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    entityDeleted: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    newCardClicked: EventEmitter<any> = new EventEmitter<any>();


    entityList: any[] = [];
    protected readonly TaggableType = TaggableType;
    loading: boolean = false;
    selectedEntity: any = null;
    totalItems: number = 0;
    page: number = 0;
    currentPage: number = 0;
    pageSize: number = 10;
    currentFilter: string = '';

    @Input("xxlCol")
    xxlCol: number = 2;
    @Input("lgCol")
    lgCol: number = 4;
    @Input("mdCol")
    mdCol: number = 6;

    constructor(private router: Router) {

    }

    ngOnInit(): void {
        if (this.parentId) {
            this._fetchData(this.getParentFilter());
            this.currentFilter = this.getParentFilter();
        } else if (this.type) {
            this._fetchData(this.getTypeFilter());
            this.currentFilter = this.getTypeFilter();
        } else {
            this._fetchData();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type && !changes.type.firstChange) {
            if (changes.type.currentValue !== changes.type.previousValue) {
                this.ngOnInit();
            }
        }
    }


    getTypeFilter(): string {
        const rsql = new RSQLCriteria();
        const builder = new RSQLFilterBuilder();
        if (this.type && this.type != 'TEMPLATE') {
            builder.column(`${this.entityName}.type`).equalTo(this.type)
        }
        rsql.filters.and(builder.toList());
        return RsqlHelper.toQuery(rsql);
    }


    getParentFilter(): string {
        const rsql = new RSQLCriteria();
        const builder = new RSQLFilterBuilder();

        if (this.type) {
            builder.column(`${this.entityName}.type`).equalTo(this.type)
        }

        // if used with "parent"
        if (this.parentId) {
            builder.column(`${this.entityName}.parentId`).equalTo(this.parentId)
        }
        rsql.filters.and(builder.toList());
        return RsqlHelper.toQuery(rsql);
    }

    onFilterChanged(filter: string) {
        this.currentFilter = filter;
        this._fetchData(filter);
        this.filterChanged.emit(filter);
    }

    onSelectedEntity(entity: any) {
        this.selectedEntity = entity;
        this.entitySelected.emit(entity);
    }

    onDeleteEntity(entityId: any) {
        this.entityService.deleteById(entityId).subscribe(value => {
            this.selectedEntity = undefined;
            this.entitySelected.emit(undefined);
            this.entityList = this.entityList.filter(entity => entity.id !== entityId);
            this.totalItems = this.totalItems - 1;
        });

        this.entityDeleted.emit(entityId);
    }

    navigateToRoute() {
        if (this.navigationDestination) {
            this.router.navigate([this.navigationDestination])
        } else {
            console.log("Cannot navigate.")
        }
    }


    loadMoreEntities() {
        if (this.currentFilter.length > 0) {
            this.entityService.findAllPaged(this.currentPage, appConstants.pageSize, {filter: this.currentFilter}).subscribe(data => {
                this.entityList = [...this.entityList, ...data.entities];
                this.currentPage++;
                this.setTotalItems(data);
            });

        } else {
            this.entityService.findAllPaged(this.currentPage, this.pageSize).subscribe(data => {
                this.entityList = [...this.entityList, ...data.entities];
                this.currentPage++;
                this.setTotalItems(data);
            });
        }
    }

    _fetchData(filter?: string): void {
        const pageSize = this.isPreviewOnly ? appConstants.previewSize : appConstants.pageSize;


        if (filter) {
            this.entityService.findAllPaged(this.page, pageSize, {filter: filter}).subscribe(value => {
                this.entityList = value.entities;
                this.currentPage = 1;
                this.setTotalItems(value);
            });

        } else {
            this.entityService.findAllPaged(this.page, pageSize).subscribe(value => {
                this.entityList = value.entities;
                this.currentPage = 1;
                this.setTotalItems(value);
            });

        }
    }

    private setTotalItems(value: { entities: T[]; totalItems: number }) {
        let items = value.totalItems;
        let current = this.currentPage;
        if (value.totalItems - this.pageSize * (current) < 0) {
            this.totalItems = 0;
        } else {
            this.totalItems = items - (this.pageSize * (this.currentPage));
        }
    }


    onNewCard() {
        this.newCardClicked.emit();
    }

    onSimpleFitlerChanged(searchTitle: string): void {

        const rsql = new RSQLCriteria();
        const builder = new RSQLFilterBuilder();

        if (this.type && this.type != 'TEMPLATE') {
            builder.column(`${this.entityName}.type`).equalTo(this.type)
        }

        if (searchTitle.length > 2) {
            builder.column(`${this.entityName}.title`).like(`*${searchTitle}*`);
        }


        rsql.filters.and(builder.toList());
        this.onFilterChanged(RsqlHelper.toQuery(rsql));


    }
}
