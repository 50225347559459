<app-selectable-card *ngIf="this.selectedItem && isComponentVisible"
                     [entity]="this.selectedItem"
                     [height]="80"
                     (cardClicked)="onCardClick($event)">
</app-selectable-card>

<div class="modal-dialog-like" *ngIf="isSelecting">
    <div class="modal-content">
        <div class="modal-header">
            <h5>{{ title | translate }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="isSelecting = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-enumeration-list [enumerationList]="this.enumerationItems"
                                  (itemSelected)="onLanguageSelected($event)"></app-enumeration-list>
        </div>
    </div>
</div>
