import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnumerationItem} from "../enumeration-list/enumeration-list.component";
import {ENUM_LANGUAGES, EnumerationService} from "../../service/enumerations/enumeration.service";
import {LanguageService} from "../../../core/service/language.service";

@Component({
    selector: 'app-enumeration-chooser',
    templateUrl: './enumeration-chooser.component.html',
    styleUrls: ['./enumeration-chooser.component.scss']
})
export class EnumerationChooserComponent {

    @Input("title")
    protected title: string = '';

    @Input("enumeration")
    protected enumeration!: string;

    @Input("allowUnselected")
    protected allowUnselected: boolean = false;

    @Output("itemSelected")
    itemSelected: EventEmitter<EnumerationItem> = new EventEmitter<EnumerationItem>();

    protected selectedItem: EnumerationItem;

    protected enumerationItems: any[];

    protected isSelecting: boolean = false;
    protected isComponentVisible: boolean = true;


    constructor(
        private enumerationService: EnumerationService,
        private languageService: LanguageService) {
    }

    ngOnInit(): void {
        this.enumerationService.getEnumeration(this.enumeration, this.languageService.getCurrentLanguageSnapshot().code).subscribe(value => {
            this.enumerationItems = value;

            if (!this.selectedItem) {
                this.selectedItem = value[0];
            }
            this.itemSelected.emit(this.selectedItem);
            this.selectedItem.selected = true;
        })
    }


    protected onCardClick($event: any) {
        this.isSelecting = true;
    }


    protected readonly onlanguagechange = onlanguagechange;

    onLanguageSelected(selectedLanguage: any) {
        this.selectedItem = selectedLanguage;
        this.itemSelected.emit(this.selectedItem);
        this.forceReRender();
        this.isSelecting = false;
    }

    forceReRender() {
        this.isComponentVisible = false;
        setTimeout(() => {
            this.isComponentVisible = true;
        }, 0);  // A brief timeout to allow Angular to destroy and recreate the component
    }

}
