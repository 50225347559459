<div id="preloader" [hidden]='!display' [class]="class">
    <div id="status">
        <div class="spinner animate__animated animate__infinite animate__slow animate__flip">
            <span class="iconify" data-height="65px" data-width="65px" data-icon="svg-spinners:blocks-wave"></span>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div id="message" *ngIf="message">
            <span>{{ message | translate }}</span>
        </div>
    </div>
</div>