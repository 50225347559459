import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {PaperCommand} from "../../apps/papers/shared/paper.model";
import {WSService} from "../service/wsservice.service";
import {DocumentEditorContainerComponent} from "@syncfusion/ej2-angular-documenteditor";
import {EditorService} from "../../apps/papers/shared/service/editor.service";
import {RichTextEditorComponent, ToolbarSettingsModel, ToolbarType} from "@syncfusion/ej2-angular-richtexteditor";


@Component({
    selector: 'app-readonly-rich-text-editor',
    templateUrl: './readonly-rich-text-editor.component.html',
    styleUrls: ['./readonly-rich-text-editor.component.scss']
})
export class ReadonlyRichTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input("theme")
    theme!: string;

    @Input("readonly")
    readonly: boolean = false;

    @Input("identity")
    identity: string = '';

    @Input("command")
    command: PaperCommand;

    content: String = '';

    @ViewChild('mainEditor')
    public container: DocumentEditorContainerComponent;

    @ViewChild("editor")
    editor: RichTextEditorComponent;

    private selectedText: string = '';
    private selectedTextPlain: string = '';

    @Input("text")
    text: string = '';
    modules = {};


    public tools: ToolbarSettingsModel = {
        type: ToolbarType.Expand,
        enable: false,
        items: [
            /*
            {
                tooltipText: "        Copy to clipboard",
                template:
                    `<button class="e-tbar-btn e-control e-btn e-lib e-icon-btn" tabindex="-1" id="custom_tbarbtn_2" style="width:100%">
                  <span class="iconify" data-height="24px" data-width="24px" data-icon="mdi:lightbulb-on-outline"></span></button>`,
                click: this.copyToClipboard.bind(this)
            },
            */

        ]
    };


    constructor(private wsService: WSService, private editorService: EditorService) {

        // this.wsService.publish("/app/papers/command", x.cmd);
    }

    ngOnDestroy(): void {

    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {

    }

    onSelectionChange() {
        this.selectedText = this.editor.getSelectedHtml();
        this.selectedTextPlain = this.editor.getSelection();
    }

    private copyToClipboardInternal(content: string) {
        navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([content], {type: "text/html"}),
                "text/plain": new Blob([content], {type: "text/plain"}),
            })
        ]).then(() => {
            console.log('HTML copied to clipboard successfully!');
        }).catch(err => {
            console.error('Failed to copy HTML to clipboard: ', err);
        });
    }

    onClipboardClick() {
        this.copyToClipboardInternal(this.selectedText);
    }

    onClipboardAllClick() {
        this.copyToClipboardInternal(this.editor.getHtml());
    }

    onClipboardPlainTextClick() {
        this.copyToClipboardInternal(this.selectedTextPlain);
    }

    onClipboardAllPlainTextClick() {
        this.copyToClipboardInternal(this.editor.getText());
    }

    /*
    onReplaceClick() {
        this.editorService.setClipboard(this.editor.getHtml());
    }
    */
}
