import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Statistics} from "./statistics.model";

@Injectable({
    providedIn: 'root'
})
export class StatisticsService {


    private baseUrl = environment.apiUrl + '/statistics';

    constructor(private http: HttpClient) {
    }

    getStatistics(ordinalDate: number): Observable<Statistics> {
        return this.http.get<Statistics>(`${this.baseUrl}/${ordinalDate}`);
    }


}
