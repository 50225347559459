<div class="text-center card animate__fadeIn animate__animated"
     (click)="onCardClick()"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [ngClass]="{'card-hover': isHovered}"
>
    <div class="hovno animate__fadeIn animate__animated">
        <div *ngIf="isHovered">
            <span class="iconify" data-width="48" data-height="48" data-icon="mdi:add"
                  style="color: #6658dd"></span>
        </div>
        <div *ngIf="!isHovered">
            <span class="iconify" data-width="48" data-height="48" data-icon="mdi:add"></span>
        </div>
    </div>
</div>
