import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeftSidebarComponent} from './left-sidebar/left-sidebar.component';
import {TopbarComponent} from './topbar/topbar.component';
import {SimplebarAngularModule} from 'simplebar-angular';
import {
    NgbAlertModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbProgressbarModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';
import {FormsModule} from '@angular/forms';
import {IconDirective} from "../../shared/directive/icon.directive";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {HttpLoaderFactory} from "../../app.module";


@NgModule({
    declarations: [
        LeftSidebarComponent,
        TopbarComponent,
        IconDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbAlertModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgbProgressbarModule,
        NgbNavModule,
        SimplebarAngularModule,
        ClickOutsideModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        LeftSidebarComponent,
        TopbarComponent,
        IconDirective
    ]
})
export class LayoutSharedModule {
}
