import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";
import {ImageSuggestionRequestApiDto, ImageSuggestionResponseApiDto} from "../model/image.model";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ImagesService {

    private apiUrl = environment.apiUrl + '/image';
    private originalImage: string | null = null;
    private imageSuggestions = new Map<string, ReplaySubject<ImageSuggestionRequestApiDto>>();

    observeImageSuggestion(id: string): Observable<ImageSuggestionRequestApiDto> {
        if (!this.imageSuggestions.has(id)) {
            this.imageSuggestions.set(id, new ReplaySubject<ImageSuggestionRequestApiDto>(1));
        }
        return this.imageSuggestions.get(id)!.asObservable();
    }

    publishSuggestion(id: string, suggestion: ImageSuggestionRequestApiDto) {
        console.log("publishing...")
        if (!this.imageSuggestions.has(id)) {
            this.imageSuggestions.set(id, new ReplaySubject<ImageSuggestionRequestApiDto>(1));
        }
        this.imageSuggestions.get(id)!.next(suggestion);
    }


    constructor(private http: HttpClient) {
    }

    suggestImage(request: ImageSuggestionRequestApiDto): Observable<ImageSuggestionResponseApiDto> {
        return this.http.post<ImageSuggestionResponseApiDto>(this.apiUrl + "/suggest", request);
    }

    fetchImageAsBase64(url: string): Observable<Promise<string>> {
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map(blob => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result as string);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            })
        );
    }

}
