<div class="row mt-1 mb-1">
    <div class="col ms-2">
    <span class="me-2 iconify-cancel" (click)="onClipboardClick()" container="body" placement="top" ngbTooltip="{{'common.button.copy-selection.tooltip' | translate }}">
       <span class="iconify" data-width="24" data-height="24" data-icon="material-symbols:markdown-copy-outline"></span>
    </span>
        <span class="me-2 iconify-cancel" (click)="onClipboardAllClick()" container="body" placement="top" ngbTooltip="{{'common.button.copy.tooltip' | translate }}">
       <span class="iconify" data-width="24" data-height="24" data-icon="material-symbols:file-copy"></span>
    </span>

        <span class="me-2 iconify-cancel" (click)="onClipboardPlainTextClick()" container="body" placement="top" ngbTooltip="{{'common.button.copy-plain-selection.tooltip' | translate }}">
       <span class="iconify" data-width="24" data-height="24" data-icon="material-symbols:content-copy-outline"></span>
    </span>
        <span class="me-2 iconify-cancel" (click)="onClipboardAllPlainTextClick()" container="body" placement="top" ngbTooltip="{{'common.button.copy-plain.tooltip' | translate }}">
       <span class="iconify" data-width="24" data-height="24" data-icon="material-symbols:file-copy-outline-rounded"></span>
    </span>

    </div>
</div>

<ngx-simplebar class="flex-grow-1 chat-messages">
    <ejs-richtexteditor
        #editor
        [(ngModel)]="text"
        (click)="onSelectionChange()"
        [toolbarSettings]='tools'
        [readonly]="readonly"
        [showCharCount]="true"
        (keyup)="onSelectionChange()">
    </ejs-richtexteditor>
</ngx-simplebar>
