import {Component, Input, OnInit} from '@angular/core';
import {ImageHistoryService} from "../../../apps/images/shared/service/image-history.service";

@Component({
    selector: 'app-image-history',
    templateUrl: './image-history.component.html',
    styleUrls: ['./image-history.component.scss']
})
export class ImageHistoryComponent implements OnInit {

    @Input()
    componentId!: string;

    @Input()
    orientation: 'vertical' | 'horizontal' = 'vertical';

    imageHistory: string[] = [];

    constructor(private imageHistoryService: ImageHistoryService) {
    }

    ngOnInit() {
        console.log('ImageHistoryComponent initialized');
        this.imageHistoryService.getHistory$(this.componentId).subscribe(history => {
            console.log("Adding to history");
            this.imageHistory = history;
        });
    }

    restoreImage(base64Image: string) {
        this.imageHistoryService.restoreImage(this.componentId, base64Image);
    }
}
