import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {AuthenticationService} from 'src/app/core/service/auth.service';
import {EventService} from 'src/app/core/service/event.service';
import {LEFT_SIDEBAR_TYPE_CONDENSED, LEFT_SIDEBAR_TYPE_DEFAULT} from '../config/layout.model';
import {Language} from '../models/language.model';
import {ProfileOptionItem} from '../models/profileoption.model';
import {LanguageService} from "../../../core/service/language.service";


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

    @Input() layoutType: string = '';
    @Input() leftSidebarTheme: string = 'light';
    languages: Language[] = [];
    profileOptions: ProfileOptionItem[] = [];
    selectedLanguage?: Language;

    loggedInUser: any = {};
    topnavCollapsed: boolean = false;

    // output events
    @Output() mobileMenuButtonClicked = new EventEmitter();
    @Output() settingsButtonClicked = new EventEmitter<boolean>();


    constructor(
        private authService: AuthenticationService,
        private eventService: EventService,
        protected languageService: LanguageService,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {

    }

    updateIcon(): void {
        const iconElement = this.elRef.nativeElement.querySelector('#trol');
        if (iconElement) {
            this.renderer.setProperty(iconElement, 'data-icon', `flagpack:${this.selectedLanguage.flag}`);
            iconElement.innerHTML = ''; // Reset the inner content
            const script = document.createElement('script');
            script.src = 'https://code.iconify.design/2/2.1.2/iconify.min.js';
            script.async = true;
            document.head.appendChild(script);
            script.onload = () => {
                if (window['Iconify']) {
                    window['Iconify'].scan(iconElement); // Re-scan just this element
                }
            };
        }
    }

    ngOnInit(): void {

        this.languageService.getLanguage().subscribe(value => {
            this.selectedLanguage = value;;
            console.log("Selected is: "+value.id);
        });


        this._fetchProfileOptions();

        this.loggedInUser = this.authService.currentUser();

        document.addEventListener('fullscreenchange', this.exitHandler);
        document.addEventListener("webkitfullscreenchange", this.exitHandler);
        document.addEventListener("mozfullscreenchange", this.exitHandler);
    }


    /**
     * Fetches profile options
     */
    _fetchProfileOptions(): void {
        this.profileOptions = [
            {
                label: 'My subscription',
                icon: 'tabler:chart-candle',
                redirectTo: '/subscription',
            },
            {
                label: 'Logout',
                icon: 'material-symbols:logout',
                redirectTo: '/auth/logout',
            }
        ];

    }

    /**
     * changes left sidebar width
     */
    changeSidebarWidth(): void {
        if (document.body.hasAttribute('data-leftbar-size') && document.body.getAttribute('data-leftbar-size') === "condensed") {
            this.eventService.broadcast('changeLeftSidebarType', LEFT_SIDEBAR_TYPE_DEFAULT);
        } else {
            this.eventService.broadcast('changeLeftSidebarType', LEFT_SIDEBAR_TYPE_CONDENSED);
        }


    }

    /**
     * exit handler for full screen mode
     */
    exitHandler(): void {
        let document: any = window.document;

        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            document.body.classList.remove('fullscreen-enable');
        }
    }

    /**
     * toggles full screen mode
     */
    toggleFullScreen(): void {
        let document: any = window.document;

        document.body.classList.toggle('fullscreen-enable');

        let elem = document.querySelector('.maximize-icon');

        if (elem.hasAttribute('data-toggle') && document.body.getAttribute('data-toggle') === "fullscreen") {
            document.body.removeAttribute('data-toggle');
        } else {
            elem.setAttribute('data-toggle', 'fullscreen')
        }

        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        this.topnavCollapsed = !this.topnavCollapsed;
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }


    selectLanguage(language: Language) {
        this.languageService.setLanguage(language);
    }
}
