import {Injectable, OnInit} from '@angular/core';
import {Language} from "../../layout/shared/models/language.model";
import {BehaviorSubject, Observable} from "rxjs";
import {L10n, setCulture} from "@syncfusion/ej2-base";
import EN_LOCALE from "@syncfusion/ej2-locale/src/en-GB.json";
import DE_LOCALE from "@syncfusion/ej2-locale/src/de.json";
import IT_LOCALE from "@syncfusion/ej2-locale/src/it.json";
import ES_LOCALE from "@syncfusion/ej2-locale/src/es.json";
import CS_LOCALE from "../../../assets/i18n/ejs/cs.json";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class LanguageService implements OnInit {
    private currentLanguage: Language;

    private readonly supportedLanguages: Language[] = [
        {
            id: 1,
            name: 'English',
            flag: 'us',
            code: 'en',
            ejsCulture: 'en-GB'
        },
        {
            id: 2,
            name: 'German',
            flag: 'de',
            code: 'de',
            ejsCulture: 'de'
        },
        {
            id: 3,
            name: 'Italian',
            flag: 'it',
            code: 'it',
            ejsCulture: 'it'
        },
        {
            id: 4,
            name: 'Spanish',
            code: 'es',
            ejsCulture: 'es',
            flag: 'es',
        },
        {
            id: 5,
            name: 'Czech',
            flag: 'cz',
            code: 'cs',
            ejsCulture: 'cs'
        }];
    private readonly localStorageKey = 'appLanguage';

    private currentLanguageSubject: BehaviorSubject<Language>;

    constructor(private translateService: TranslateService) {
        const storedLanguage = localStorage.getItem(this.localStorageKey);
        const browserLang = this.translateService.getBrowserLang();
        const isBrowserLangSupported = this.supportedLanguages.some(lang => lang.code === browserLang);

        const initialLanguage = storedLanguage
            ? JSON.parse(storedLanguage)
            : isBrowserLangSupported
                ? this.supportedLanguages.find(lang => lang.code === browserLang)
                : this.supportedLanguages[0];


        this.currentLanguageSubject = new BehaviorSubject<Language>(initialLanguage);
        this.translateService.setDefaultLang('en');
        console.log("init language: "+ initialLanguage.code);
        this.setLanguage(initialLanguage);
    }

    // Set current language and store it in local storage
    setLanguage(language: Language): void {
        switch (language.code){
            case 'en':
                L10n.load({ en: EN_LOCALE["en-GB"] });
                break;
            case 'de':
                L10n.load({ de: DE_LOCALE.de });
                break;
            case 'it':
                L10n.load({ it: IT_LOCALE.it });
                break;
            case 'es':
                L10n.load({ es: ES_LOCALE.es });
                break;
            case 'cs':
                L10n.load({ cs: CS_LOCALE.cs });
                break;
        }
        setCulture(language.ejsCulture);
        this.translateService.use(language.code);
        localStorage.setItem(this.localStorageKey, JSON.stringify(language));
        this.currentLanguageSubject.next(language); // Notify all subscribers
    }

    // Get the current language as an Observable
    getLanguage(): Observable<Language> {
        return this.currentLanguageSubject.asObservable();
    }

    // Get the current language as a snapshot (useful for synchronous needs)
    getCurrentLanguageSnapshot(): Language {
        return this.currentLanguageSubject.getValue();
    }

    ngOnInit(): void {

    }

    getSupportedLanguages(): Language[] {
        return this.supportedLanguages;
    }

}
