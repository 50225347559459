<span
        (click)="showDialog(); $event.stopPropagation()">
<ng-container *ngFor="let tag of entityTags | slice:0:3">
    <span [ngClass]="'me-1 badge badge-outline-' + tag.color"> {{ tag.title }}</span>
</ng-container>


    <span *ngIf="entityTags.length - 3 > 0">
        <span class="iconify-inline" data-icon="line-md:plus"></span>{{ entityTags.length - 3 }}
    </span>

    <span *ngIf="entityTags.length == 0">
         <span class="iconify-inline" data-icon="icons8:price-tag" data-width="24" data-height="24"></span>
    </span>

</span>
