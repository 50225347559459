import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ENUM_LANGUAGES, EnumerationService} from "../../service/enumerations/enumeration.service";
import {LanguageService} from "../../../core/service/language.service";
import {EnumerationItem} from "../enumeration-list/enumeration-list.component";

@Component({
    selector: 'app-language-chooser',
    templateUrl: './language-chooser.component.html',
    styleUrls: ['./language-chooser.component.scss']
})
export class LanguageChooserComponent implements OnInit {


    protected selectedLanguage: EnumerationItem;

    protected languages: any[];

    protected isSelecting: boolean = false;
    protected isComponentVisible: boolean = true;

    @Output("itemSelected")
    itemSelected: EventEmitter<EnumerationItem> = new EventEmitter<EnumerationItem>();

    constructor(private enumerationService: EnumerationService, private languageService: LanguageService) {
    }

    ngOnInit(): void {
        this.enumerationService.getEnumeration(ENUM_LANGUAGES, this.languageService.getCurrentLanguageSnapshot().code).subscribe(value => {
            this.languages = value;

            for (const lang of value) {
                if (lang.code == this.languageService.getCurrentLanguageSnapshot().code) {
                    this.selectedLanguage = lang;
                }
            }
            if (!this.selectedLanguage) {
                this.selectedLanguage = value[0];
            }
            this.itemSelected.emit(this.selectedLanguage);
            this.selectedLanguage.selected = true;
        })
    }


    protected onCardClick($event: any) {
        this.isSelecting = true;
    }


    protected readonly onlanguagechange = onlanguagechange;

    onLanguageSelected(selectedLanguage: any) {
        this.selectedLanguage = selectedLanguage;
        this.itemSelected.emit(this.selectedLanguage);
        this.forceReRender();
        this.isSelecting = false;
    }

    forceReRender() {
        this.isComponentVisible = false;
        setTimeout(() => {
            this.isComponentVisible = true;
        }, 0);  // A brief timeout to allow Angular to destroy and recreate the component
    }

}


