import { Injectable } from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";
import {ToastModel} from "@syncfusion/ej2-angular-notifications";

export interface ExtendedToastModel extends ToastModel {
  level: 'info' | 'success' | 'warning' | 'danger';
}

export enum ToastType {
  TOAST = 'toast', // simple toast component with title, icon and content
}

interface Event {
  type: ToastType;
  payload?: ExtendedToastModel;
}

//Defines event callback
type EventCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private handler = new Subject<Event>();
  constructor() { }

  /**
   * Broadcast the event
   * @param type type of event
   * @param payload payload
   */
  broadcast(type: ToastType, payload: ExtendedToastModel): void {
    this.handler.next({ type, payload });
  }

  /**
   * Subscribe to event
   * @param type type of event
   * @param callback call back function
   */
  subscribe(type: string, callback: EventCallback): Subscription {
    return this.handler.pipe(
        filter((event) => event.type === type)).pipe(
        map(event => event.payload))
        .subscribe(callback);
  }
}
