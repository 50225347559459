<div class="row mb-2">
    <div class="col-12 text-end">
        <button class="btn btn-outline-dark btn-xs waves-effect waves-light"
                (click)="isCollapsed = !isCollapsed"
        >
    <span class="iconify" data-icon="ic:outline-filter-alt"
          [attr.aria-expanded]="!isCollapsed"></span>
        </button>
    </div>
</div>
<div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="row">
        <div class="col-4">
            <input type="text"
                   id="glare-title-in"
                   name="glare-title"
                   class="form-control"
                   placeholder="{{'entity.filter.search.placeholder' | translate }}"
                   required
                   ngDefaultControl
                   [(ngModel)]="searchTitle">
        </div>
        <div class="col-1">
            <button
                    type="button"
                    class="btn btn-outline-primary ms-2"
                    aria-controls="tags"
                    [ngbPopover]="popContent"
                    [autoClose]="'outside'"
                    [placement]="'end'"
                    (click)="showTagPopover = true; $event.stopPropagation()"
            >

                <span class="iconify" data-icon="icons8:price-tag"></span>

            </button>
        </div>

        <div class="col-1 float">
            <button
                    type="button"
                    class="btn btn-outline-primary ms-2"
                    aria-controls="filter"
                    (click)="this.emitFilter()"
            >
                <span class="iconify" data-icon="line-md:search"></span>
            </button>
        </div>
    </div>
</div>

<ng-template #popContent>
    <app-tag-subfilter *ngIf="showTagPopover"
                       [selectedTags]="selectedTags"
                       (addTagToFilter)="handleAddTag($event)"
                       (removeTagFromFilter)="handleRemoveTag($event)"
                       (close)="showTagPopover = false">
    </app-tag-subfilter>
</ng-template>
