<button *ngIf="buttonState == 'saved'" class="btn btn-success"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;">
    <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:check-all"></span> Saved successfully
</button>

<button *ngIf="buttonState == 'waiting'" class="btn btn-info"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;"

        (mouseenter)="waitHover = true"
        (mouseleave)="waitHover = false"
        (click)="forceUpload()">
    <div *ngIf="countdownValue > 0">
        <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:uploading-loop"></span> <span
            *ngIf="!waitHover"> Saving
        in {{ countdownValue }}s</span>
        <span *ngIf="waitHover"> Save instantly? </span>
    </div>

    <div *ngIf="countdownValue < 1">
        <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:cloud-up"></span>
    </div>
</button>

<button *ngIf="buttonState == 'error'" class="btn btn-danger"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 1001;">
    <span class="iconify" data-height="24px" data-width="24px" data-icon="line-md:cloud-off-outline-loop"></span> Saving
    in {{ countdownValue }}s
</button>
