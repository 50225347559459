import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TaggableType} from "../../../service/tags/tag.service";
import {AuthenticationService} from "../../../../core/service/auth.service";
import {UserTemplate} from "../../../../apps/papers/shared/paper.model";

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent {

  @Input()
  entity!: UserTemplate;

  @Input()
  taggable!: TaggableType;

  @Output()
  cardClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  isHovered: boolean = false;

  constructor(protected authenticationService: AuthenticationService) {
  }

  onCardClick(card: any) {
    this.cardClicked.emit(card);
  }

  onMouseEnter(template: any) {
    this.isHovered = true;
  }

  onMouseLeave(template: any) {
    this.isHovered = false;
  }

  onDeleteClick(event: Event) {
    console.log("Delete clicked");
    this.deleteClicked.emit();
    event.stopPropagation();
  }

  protected readonly TaggableType = TaggableType;

  onFavoriteClick($event: MouseEvent) {
    
  }
}
