import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ImageHistoryService {
    private componentMap = new Map<string, {
        currentImage: BehaviorSubject<string | null>,
        history: BehaviorSubject<string[]>,
        rotationIndex: number  // Track the rotation index for circular replacement
    }>();

    // Initialize component data if it does not already exist
    private initializeComponent(componentId: string) {
        if (!this.componentMap.has(componentId)) {
            this.componentMap.set(componentId, {
                currentImage: new BehaviorSubject<string | null>(null),
                history: new BehaviorSubject<string[]>([]),  // BehaviorSubject to hold the history
                rotationIndex: 1  // Start rotation index after the original image
            });
        }
    }

    // Observable for the current image of a specific component
    getCurrentImage$(componentId: string): Observable<string | null> {
        this.initializeComponent(componentId);
        return this.componentMap.get(componentId)!.currentImage.asObservable();
    }

    // Observable for the image history of a specific component
    getHistory$(componentId: string): Observable<string[]> {
        this.initializeComponent(componentId);
        return this.componentMap.get(componentId)!.history.asObservable();
    }

    // Method to update the current image for a specific component
    setCurrentImage(componentId: string, base64Image: string, isOriginal: boolean = false) {
        this.initializeComponent(componentId);
        const componentData = this.componentMap.get(componentId);
        if (componentData) {
            componentData.currentImage.next(base64Image);
            this.addToHistory(componentId, base64Image, isOriginal);
        }
    }

    // Method to add an image to the history of a specific component
    private addToHistory(componentId: string, base64Image: string, isOriginal: boolean) {
        this.initializeComponent(componentId);
        const componentData = this.componentMap.get(componentId);
        if (componentData) {
            let history = componentData.history.getValue(); // Get the current history array

            if (isOriginal) {
                // If it's the original image, always keep it as the first entry
                if (history.length === 0 || history[0] !== base64Image) {
                    history = [base64Image]; // Set the original image as the first entry
                }
            } else {
                // When history has fewer than 5 images, just add to the end
                if (history.length < 5) {
                    history.push(base64Image);
                } else {
                    // Replace the image at the current rotation index
                    history[componentData.rotationIndex] = base64Image;
                    // Move the rotation index to the next position
                    componentData.rotationIndex++;
                    // If rotationIndex exceeds 4 (i.e., the last non-original position), reset it to 1
                    if (componentData.rotationIndex > 4) {
                        componentData.rotationIndex = 1;
                    }
                }
            }

            componentData.history.next(history); // Emit the updated history
        }
    }

    // Method to restore an image for a specific component
    restoreImage(componentId: string, base64Image: string) {
        this.initializeComponent(componentId);
        const componentData = this.componentMap.get(componentId);
        if (componentData) {
            componentData.currentImage.next(base64Image);
        }
    }

    // Optionally, clear the data for a specific component
    clearComponentData(componentId: string) {
        this.componentMap.delete(componentId);
    }
}
