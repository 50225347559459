import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TaggableType} from "../../service/tags/tag.service";
import {AbstractEntityServiceService} from "../../service/abstract-entity-service.service";
import {appConstants} from "../../../../environments/environment";
import {Paper} from "../../../apps/papers/shared/paper.model";
import {EntityFilterComponent} from "../entity-filter/entity-filter.component";
import {RSQLCriteria, RSQLFilterBuilder} from "rsql-criteria-typescript";
import {RsqlHelper} from "../../utils/rsql-helper";
import {EventService} from "../../../core/service/event.service";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {ej} from "@syncfusion/ej2-data/dist/global";

@Component({
    selector: 'app-entity-table',
    templateUrl: './entity-table.component.html',
    styleUrls: ['./entity-table.component.scss']
})
export class EntityTableComponent<T> implements OnInit, OnDestroy {

    @Input()
    entityName!: string;
    @Input()
    entityService!: AbstractEntityServiceService<T>;
    @Input()
    taggable!: TaggableType;
    @Input()
    parentId?: string;

    @Input()
    isSimpleFilter?: boolean;

    @Output()
    filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    entitySelected: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    entityDeleted: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    entityCreated: EventEmitter<any> = new EventEmitter<any>();

    entityList: any[] = [];
    protected readonly TaggableType = TaggableType;
    loading: boolean = true;
    selectedEntity: any = null;
    totalItems: number = 0;
    page: number = 0;
    currentPage: number = 0;
    pageSize: number = 20;
    currentFilter: string = '';

    private subscription: Subscription;

    constructor(private eventService: EventService) {

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    ngOnInit(): void {
        this.subscription = this.eventService.subscribe("chat-added", payload => {
            this.entityList.unshift(payload);
        });
        if (this.parentId) {
            this._fetchData(this.getParentFilter());
        } else {
            this._fetchData();
        }
    }


    getParentFilter(): string {
        const rsql = new RSQLCriteria();
        const builder = new RSQLFilterBuilder();

        // if used with "parent"
        if (this.parentId) {
            builder.column(`${this.entityName}.parentId`).equalTo(this.parentId)
        }
        rsql.filters.and(builder.toList());
        return RsqlHelper.toQuery(rsql);
    }

    onFilterChanged(filter: string) {
        this.currentFilter = filter;
        this.loading = true;
        this._fetchData(filter);
        this.filterChanged.emit(filter);
    }

    onSelectedEntity(entity: any) {
        this.selectedEntity = entity;
        this.entitySelected.emit(entity);
    }

    onDeleteEntity(entityId: any) {
        this.entityService.deleteById(entityId).subscribe(value => {
            this.selectedEntity = undefined;
            this.entitySelected.emit(undefined);
            this.entityList = this.entityList.filter(entity => entity.id !== entityId);
            this.totalItems = this.totalItems - 1;
        });

        this.entityDeleted.emit(entityId);
    }

    loadMoreEntities() {
        if (this.currentFilter.length > 0) {
            this.entityService.findAllPaged(this.currentPage, appConstants.pageSize, {filter: this.currentFilter}).subscribe(data => {
                this.entityList = [...this.entityList, ...data.entities];
                this.currentPage++;
                this.setTotalItems(data);
            });

        } else {
            this.entityService.findAllPaged(this.currentPage, this.pageSize).subscribe(data => {
                this.entityList = [...this.entityList, ...data.entities];
                this.currentPage++;
                this.setTotalItems(data);
            });
        }
    }

    _fetchData(filter?: string): void {
        // pridat jako parametr: {filter: RsqlHelper.toQuery(rsql)}
        if (filter) {
            this.entityService.findAllPaged(this.page, appConstants.pageSize, {filter: filter}).subscribe(value => {
                this.entityList = value.entities;
                this.currentPage = 1;
                this.setTotalItems(value);
                this.loading = false;
            });

        } else {
            this.entityService.findAllPaged(this.page, appConstants.pageSize).subscribe(value => {
                this.entityList = value.entities;
                this.currentPage = 1;
                this.setTotalItems(value);
                this.loading = false;
            });

        }
    }

    private setTotalItems(value: { entities: T[]; totalItems: number }) {
        let items = value.totalItems;
        let current = this.currentPage;
        if (value.totalItems - this.pageSize * (current) < 0) {
            this.totalItems = 0;
        } else {
            this.totalItems = items - (this.pageSize * (this.currentPage));
        }
    }


    onCreateButton() {
        this.entityCreated.emit();
    }
}
