import {Component, Input, OnInit} from '@angular/core';
import {TaggableType, TagService} from "../../../service/tags/tag.service";
import {DUMMY_TAGS, SELECTED, TagEntity} from "../../../service/tags/tag.model";
import {PaperTemplateComponent} from "../../../../apps/papers/paper-template/paper-template.component";
import {PaperCreationBox} from "../../../../apps/papers/shared/paper.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TagDialogComponent} from "../tag-dialog/tag-dialog.component";

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

    @Input() tags: TagEntity[] = [];
    @Input() entityTagsUuids!: string[];


    entityTags: TagEntity[] = [];
    @Input() parentType!: TaggableType;
    @Input() parentId!: string;

    showTagPopover: boolean = false;

    constructor(private tagService: TagService,
                private modalService: NgbModal) {

    }

    showDialog() {
        const ngbModalRef = this.modalService.open(TagDialogComponent, {size: 'sm'});
        ngbModalRef.componentInstance.entityTags = this.entityTags;
        ngbModalRef.componentInstance.addTagToEntity.subscribe((tagEntity: TagEntity) => {
            this.handleAddTag(tagEntity);
        });
        ngbModalRef.componentInstance.removeTagFromEntity.subscribe((tagEntity: TagEntity) => {
            this.handleRemoveTag(tagEntity)
        });

        ngbModalRef.componentInstance.editTag.subscribe((tagEntity: TagEntity) => {
            this.handleEditTag(tagEntity);
        });

        ngbModalRef.componentInstance.closeButton.subscribe((tagEntity: TagEntity) => {
            ngbModalRef.dismiss();
        });
    }


    handleAddTag(tagEntity: TagEntity) {
        this.tagService.addTagToEntity(this.parentId, this.parentType, tagEntity.id).subscribe(value => {
            // check if duplicate is not being included
            if (!this.entityTags.find(tag => tag.id == tagEntity.id)) {
                this.entityTags.push(tagEntity);
            }
        })
    }

    handleRemoveTag(tagEntity: TagEntity) {
        this.tagService.deleteTagFromEntity(this.parentId, this.parentType, tagEntity.id).subscribe(value => {
            this.entityTags = this.entityTags.filter(t => t.id !== tagEntity.id);
        });
    }

    handleEditTag(tagEntity: TagEntity) {

    }

    ngOnInit(): void {
        this.tags = [];
        if (this.entityTagsUuids == null || this.entityTagsUuids.length == 0) {
            this.entityTags = [];
        } else {
            this.tagService.getTags(this.entityTagsUuids).subscribe(value => this.entityTags = value);
        }
    }
}
